import React, { useState } from "react";
import TabPenta from "react-lib/frameworks/TabPenta";
import NodeStat from "./NodeStat";
import NodeMatrix from "./NodeMatrix";
import NodeSummary from "./NodeSummary";
import NodeAdmin from "./NodeAdmin";
import XDTestSummary from "./XDTestSummary";
import GameResult from "../IHGAME/GameResult";

const XDSummary = (props: any) => {

  return(
    <div style={{height: "90vh"}}>
      <TabPenta
        selectedTabIndex={0}
        tabWidth={100}
        data={[
          {
            key: "Test Results",
            name: "Test Results",
            render: () => (
              <XDTestSummary
                xdId={props.xdId}
                onEvent={props.onEvent}
                setProp={props.setProp}    
                duckCon={props.duckCon}
                xddata={props.xddata}
                testDataReady={props.testDataReady}
              />
            )
          },
          {
            key: "Game Results",
            name: "Game Results",
            render: () => (
              <GameResult
                xdId={props.xdId}
                onEvent={props.onEvent}
                setProp={props.setProp}    
                duckCon={props.duckCon}
                ihGameList={props.ihGameList}
                ihGameStatList={props.ihGameStatList}
                toWebDownloadUrl={props.toWebDownloadUrl}
                clientUserDict={props.clientUserDict}
              />
            )
          },
          { 
            key: "Role Admin",
            name: "Role Admin",
            render: () => (
              <NodeAdmin 
                xdedit={props.xdedit}
                translate={props.translate}
                onEvent={props.onEvent}
                setProp={props.setProp}      
                xdmaster={props.xdmaster}
                xddata={props.xddata}
              />
            )
          },
          {
            key: "Role Stat",
            name: "Role Stat",
            render: () => (
              <NodeStat
                xdedit={props.xdedit}
                translate={props.translate}
                onEvent={props.onEvent}
                setProp={props.setProp}      
                xdmaster={props.xdmaster}
                xddata={props.xddata}
              />
            )
          }, 
          {
            key: "Relations",
            name: "Relations",
            render: () => (
              <NodeSummary
                xdmaster={props.xdmaster}
                xddata={props.xddata}
                view={props.view}
              />
            )
          },
          {
            key: "Matrix",
            name: "Matrix",
            render: () => (
              <NodeMatrix
                xdmaster={props.xdmaster}
                xddata={props.xddata}
                view={props.view}
              />
            )
          },          
        ]}
      />
    </div>
  )
}



export default XDSummary;
