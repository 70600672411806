import axios from "axios";
import {
    CreateUIv2State,
    CreateUIv2Event,
} from './CreateUIv2';
import { m } from "./TxDef";
import UxDef from "./UxDef";
import { Element, ScreenData } from './CreateUIv2Jsx';

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'
import TxDef from "./TxDef";
import { renderJsx } from './CreateUIv2Jsx'
import { GetDivGridCellsFromId } from "./DivGridInterface";

type Handler = WasmHandler<CreateUIv2State, CreateUIv2Event>

export const CreateUIv2Load: Handler = async (controller, params) => {
    if (controller.getState().screenId) {
        CreateUIv2GetData(controller, { screenId: controller.getState().screenId })
    } else {
        controller.db.collection("preferences")
            .doc(controller.user.email)
            .get()
            .then((result: any) => {
                if (result.data()) {
                    controller.setState({
                        screenId: result.data().screenId || ""
                    })
                    CreateUIv2GetData(controller, { screenId: result.screenId} )
                } else {
                    controller.db.collection("preferences")
                        .doc(controller.user.email)
                        .set({ screenId: "" })
                }
            })
    }
}

const CreateUIv2GetData: Handler = async (controller, params) => {
    if (params.screenId) {
        controller.db.collection("uigen2screen")
            .doc(params.screenId || "")
            .get()
            .then((result: any) => {
                let screenData: ScreenData = result.data();
                screenData.elements = (screenData.elements || [])
                .map((el: Element) => {
                    if (el.props?.id)
                        return el
                    if (el.props && screenData.name)
                        try {
                            el.props.id = { 
                                value: `${screenData.name.replaceAll(' ', '_')}-${el.name}-${el.id}`, 
                                type: 'value'
                            };
                        } catch (e) {
                            console.log("Can't set element id automatically");
                            console.log(e);
                        }
                    return el
                });
                controller.setState({ 
                    editedElementId: null,
                    screenData: { 
                        // ...controller.getState().screenData, 
                        ...screenData
                    },  
                    selectedProject: screenData.project,
                })
            });
    }
}

export const CreateUIv2SetData: Handler = async (controller, params) => {
    controller.db
    .collection("uigen2screen")
    .doc(params.id)
    .set(params.doc)
}

export const AdjustUIv2Size: Handler = (controller, params: { axis: string, increase: boolean}) => {
    if (params.axis === "height") {
        const height = controller.getState().screenData?.height || 0
        if (params.increase && height <= 95) {
            controller.setProp("screenData.height", height + 5)
        } else if (!params.increase && height > 5) {
            controller.setProp("screenData.height", height - 5)
        }
    } else if (params.axis === "width") {
        const width = controller.getState().screenData?.width || 0
        if (params.increase && width <= 95) {
            controller.setProp("screenData.width", width + 5)
        } else if (!params.increase && width > 5) {
            controller.setProp("screenData.width", width - 5)
        }
    }
}

export const AddChildElement: Handler = async (controller, params) => {
    let elements = controller.getState().screenData?.elements || []
    const maxId = Math.max(...elements.map((elem: any) => elem.id))
    if (typeof maxId === 'number') {
        elements.push({
            id: maxId + 1,
            name: params.ux.name,
            from: params.ux.from,
            void: params.ux.void,
            parent: params.parent,
            seq: maxId + 1,
            props: Object.keys(params.ux).includes("defaultProps") ? Object.assign({}, params.ux.defaultProps) : {},
            ...(params.ux.label ? {label: params.ux.label} : {}),
        })
    }
    controller.setProp("screenData.elements", elements)
}

export const AddTxElement: Handler = async (controller, params) => {  
  console.log("inside add tx element")
  console.log(params.tx.tree)
  let elements = controller.getState().screenData?.elements || []
  elements = addTxRecursive(params.parent, params.tx.tree, elements)
  controller.setProp("screenData.elements", elements)
}

const addTxRecursive = (parent: number, tree: any, elements: Element[]) => {
  console.log(parent, tree)
  const maxId = Math.max(...elements.map((elem: any) => elem.id))
  if (typeof maxId === 'number') {
      elements.push({
          id: maxId + 1,
          name: tree.type.name,
          from: tree.type.from,
          void: tree.type.void,
          parent: parent,
          seq: maxId + 1,
          props: Object.assign({}, tree.props),
          ...(tree.type.label ? {label: tree.type.label} : {}),
      })
  }
  for (const child of tree.children) {
    elements = addTxRecursive(maxId + 1, child, elements)
  }
  return elements
}

export const RemoveElementAndChildren: Handler = async (controller, params) => {
    let elements = controller.getState().screenData?.elements || []
    const exclude_list = getElementandChildrenId(elements, params.id, [])
    controller.setProp("screenData.elements", elements.filter((elem: any) => !exclude_list.includes(elem.id)))
}

export const SwapChildren: Handler = async (controller, params) => {
    let elements = controller.getState().screenData?.elements || []
    const siblings = elements.filter((elem: any) => elem.parent === params.parent)
        .sort((a, b) => (a.seq! - b.seq!))
    console.log(params)
    console.log(siblings)
    console.log(elements)
    if (params.up) {
        for (var i=0; i < siblings.length - 1; i++) {
            if (siblings[i+1].id === params.id) {
                console.log("match up", i, siblings[i].seq, siblings[i+1].seq)
                const seq = siblings[i].seq
                console.log(seq)
                siblings[i].seq = siblings[i+1].seq
                console.log(siblings[i])
                siblings[i+1].seq = seq
                console.log(siblings[i+1])
            }
        }
    } else if (!params.up) {
        for (var i=0; i < siblings.length - 1; i++) {
            if (siblings[i].id === params.id) {
                console.log("match down", i)
                const seq = siblings[i].seq
                siblings[i].seq = siblings[i+1].seq
                siblings[i+1].seq = seq
            }
        }
    }
    console.log(siblings)
    console.log(elements)
    controller.setProp("screenData.elements", elements)
}

export const ChangeParent: Handler = (controller, params) => {
    let elements = controller.getState().screenData?.elements || []
    for (var i=0; i < elements.length; i++) {
        if (elements[i].id === controller.getState().editedElementId) {
            elements[i].parent = params.parent
        }
    }
    controller.setProp("screenData.elements", elements)
}

export const SetEditedElementId: Handler = (controller, params) => {
    if (controller.getState().editedElementId !== params.id) {
        const divGridCells = GetDivGridCellsFromId(controller, params);
        console.log(divGridCells);
        controller.setState({
            editedElementId: params.id,
            openGridCanvas: false,
            divGridCells: divGridCells ? divGridCells : []
        });
    }
}

export const CopyJsx: Handler = async (controller, params) => {
    const content = renderJsx(
        controller.getState().screenData || {})
    controller.window.navigator.clipboard.writeText(content)
}

export const CopyFirebaseToken: Handler = async (controller, params) => {
    controller.user.getIdToken(true).then((idToken: any) => {
        controller.window.navigator.clipboard.writeText(idToken)
    }).catch((err: any) => {
        alert(`Error ${err}`)
    })
}

export const ChangeScreenData: Handler = async (controller, params) => {
    console.log(params)
    const screenId = controller.getState().screenId
    if (screenId) {
        controller.db.collection("uigen2screen")
            .doc(screenId)
            .update(params);
        UpdateScreenCache(controller, { id: screenId, data: params });
        CreateUIv2GetData(controller, { screenId: screenId })
    }
}

export const AddScreen: Handler = async (controller, params) => {
    console.log(params)
    controller.db.collection("uigen2screen")
        .add({
            elements: [
                { id: 0, name: "div", parent: -1, seq: 0 }
            ],
            height: 80,
            name: "New Screen",
            project: "New Project",
            screenPropsDefault : {},
            width: 80,
            forwardRef: false,
        })
        .then(async (docRef: any) => {
            controller.setState({
                screenId: docRef.id
            });
            UpdateScreenCache(controller, 
                { 
                    id: docRef.id, 
                    data: { name: "New Screen", project: "New Project" }
                });
            controller.db
                .collection("preferences")
                .doc(controller.user.email)
                .set({
                    screenId: docRef.id
                })
            CreateUIv2GetData(controller, { screenId: docRef.id })
        })
        .catch((err: any) => {
            console.log(err)
        })
}

const UpdateScreenCache: Handler = async (controller, params) => {
    let screenCache: any = (await controller.db
        .collection("cache")
        .doc("uigen2screen")
        .get()).data();
    screenCache[params.id] = screenCache?.[params.id] ? 
                             { ...screenCache[params.id], ...params.data } : 
                             params.data;
    controller.db
        .collection("cache")
        .doc("uigen2screen")
        .set(screenCache);
    let data: { [project: string]: any } = {}
    Object.entries(screenCache).forEach((entry: any) => {
        console.log(entry);
        if (!Object.keys(data).includes(entry[1].project)) {
            data[entry[1].project] = []
        }
        data[entry[1].project].push({id: entry[0], name: entry[1].name});
    });
    for (const project of Object.keys(data)) {
        data[project] = data[project].sort((a: any, b: any) => a.name < b.name ? -1 : 1)
    }
    controller.setState({
        projectScreens: data
    });
}

export const DuplicateScreen: Handler = async (controller, params) => {
    console.log(params)
    const screenData = controller.getState().screenData
    controller.db.collection("uigen2screen")
        .add({
            ...screenData,
            name: screenData?.name + " (copy)",
            project: screenData?.project
        })
        .then((docRef: any) => {
            controller.setState({
                screenId: docRef.id
            })
            UpdateScreenCache(controller, 
                { 
                    id: docRef.id, 
                    data: { 
                        name: screenData?.name + " (copy)", 
                        project: screenData?.project 
                    }
                });
            controller.db.collection("preferences")
                .doc(controller.user.email)
                .update({
                    screenId: docRef.id
                })
            CreateUIv2GetData(controller, { screenId: docRef.id })
        })
        .catch((err: any) => {
            console.log(err)
        })
}

export const ToggleMemo: Handler = async (controller, params) => {
  const screenMemo = controller.getState().screenData?.memo || false
  controller.setProp("screenData.memo", !screenMemo)
}

export const ToggleForwardRef: Handler = async (controller, params) => {
    const screenForwardRef = controller.getState().screenData?.forwardRef || false
    controller.setProp("screenData.forwardRef", !screenForwardRef)
}

export const ToggleIsMounted: Handler = async (controller, params) => {
    const screenIsMounted = controller.getState().screenData?.isMounted || false
    controller.setProp("screenData.isMounted", !screenIsMounted)
}

export const ImportElementsFromFile: Handler = async (controller, params) => {
    const oldScreenData = controller.getState().screenData!;
    const text = await readFileAsync(params.file, true) as string
    let input = "";
    let active = false;
    const start = "/* Original screen Data ************************************************************"
    const stop = "*********************************************************************************** */"
    for (const line of text.split("\n")) {
        if (line.includes(start)) {
            active = true;
            console.log(line);
        } else if (line.includes(stop)) {
            active = false;
        } else if (active) {
            input += line;
            console.log(line);
        }
    }
    const newScreenData = {
        ...JSON.parse(input),
        name: oldScreenData.name,
        project: oldScreenData.project,
    };
    console.log(newScreenData);
    controller.setProp("screenData", newScreenData);
}

export const ImportPicture: Handler = async (controller, params) => {
    if (!params.editedElementId) return console.log("no editedElementId");
    readFileAsync(params.file, false)
        .then(async (res) => {
            let image = new FormData();
            image.append('image', new Blob([res as ArrayBuffer]), params.file);
            await axios.post(`${controller.data.FASTAPI}/api/ui/extract-ui/`,
                image,
                {
                    headers: { 
                        "Authorization": `Token ${await controller.user.getIdToken()}`
                    }
                }
            )
            .then(response => {
                console.log(response.data);
                const tx = {
                    tree: m(UxDef.div,
                            { style: response.data.style },
                            response.data.children.map((child: any) => m(
                                UxDef.div, child, []
                            )))
                }
                AddTxElement(controller, 
                    { 
                        parent: params.editedElementId,
                        tx: tx 
                    });
            })
            .catch(err => {
                console.log(err);
            });
        }).catch((err: any) => {
            console.log(err);
        });
}

// Utils --------------------------------------------------------------------------------------------

const readFileAsync = (file: File, text:boolean = false) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      if (text) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
        // reader.readAsDataURL(file)
      }
    })
}

const getElementandChildrenId = (elements: any[], id: number, output: number[]) => {
    output.push(id)
    const children = elements.filter((elem: any) => elem.parent === id)
    for (const child of children) {
        output = getElementandChildrenId(elements, child.id, output)
    }
    return output
}


const addScreen = (controller: any) => {
    controller.db.collection("uigen2screen").add(
        {
            name: "CardEncounter",
            project: "cudent",
            screenPropsDefault: {},
            height: 80,
            width: 80,
            elements: [
                {
                    id: 0,
                    name: 'div',
                    from: null,
                    parent: -1,
                    seq: 0,
                }
            ]
        }
    )
}

const updateScreen = (controller: any) => {
    const id = "Cudent_MainREG"
    controller.db.collection("uigen2screen")
        .get()
        .then((res: any) => {
            res.docs.forEach((doc: any) => {
                if (doc.id === id) {
                    let data = doc.data()
                    data.elements.forEach((element: any) => {
                        if (["div", "input", "label"].includes(element.name)) {
                            element.from = null
                        } else {
                            element.from = 'semantic-ui-react'
                        }
                    })
                    controller.db.collection("uigen2screen").doc(id).update({
                        elements: data.elements
                    })
                }    
            })
        })
        .catch((err: any) => {
            console.log(err)
        })
}
