import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from 'firebase/compat/app';
import { AnimeSpec } from './AnimeType';

export type State = {
  lessonList?: any[] | null,
  lessonId?: string | null
  lessonData?: any,
  preferences?: any,
}

export const StateInitial = {
  lessonList: null,
  lessonId: null,
  lessonData: null,
}

export type Event =
  { message: "LNGetList", params: {} }
  | { message: "LNGetLessonData", params: { id : string } }
  | { message: "SavePreferences", params: any }
  | { message: "LNUploadVideo", params: { 
      id: string, index: number, newUpload: boolean,
      videoUrl: string, videoExt: string, thumbUrl: string, 
      setUploadModal: any, setUploading: any, setPctComplete: any } }
  | { message: "GetVideoList", params: { setVideoList: any } }
  | { message: "GetVideoById", params: { selectedVideo: any, setClips: any } }
  | { message: "PublishVideo", 
      params: { 
        video: any, selectedClip: any, uploadClipData: any, uploadClipName: string, 
        uploadClipThumbnailUrl: any, videoList: any, setVideoList: any, 
        setVideoUrl: any, setPublishStatus: any 
      } }
  | { message: "UpdateClipInfo", params: { selectedClip: any } }
  | { message: "GetAnimation", params: { id: string } }
  | { message: "SaveAnimation", params: { id: string, animation: AnimeSpec } }
  | { message: "AddNewVideo", params: { name: string } }

export type Handler = WasmHandler<State, Event>

export type Data = {
}

export const DataInitial: Data = {  
}

export const GetList: Handler = async (controller, params) => {
  const lessonList = ((await controller.db.collection("cache").doc("Lesson").get())
    .data()?.items || []);
  controller.setState({lessonList: lessonList});
}

export const GetLessonData: Handler = async (controller, params) => {
  if (!params?.id) return console.log("No lesson ID")
  const lessonData = (await controller.db.collection("Lesson").doc(params.id).get())?.data();
  if (lessonData) {  
    controller.setState({lessonData, lessonId: params.id});
    if (controller.getState().preferences?.lessonId !== params.id) {
      controller.handleEvent({message: "SavePreferences", params: {lessonId: params.id}});
    }
  }
  params?.setLoading(false);
}

export const UploadVideo: Handler = async (controller, params) => {
  let { id, index, newUpload, newVideoName, videoUrl, videoExt, 
    thumbUrl, setUploadModal, setUploading, setPctComplete 
  } = params;
  if (!id || !Number.isInteger(index) || !videoUrl || !videoExt) 
    return console.log("Insufficient params");
  
  let videoBlob = await (await fetch(videoUrl)).blob();
  let thumbBlob = await (await fetch(thumbUrl)).blob();
  if (!videoBlob || !thumbBlob) return console.log("No video or thumb data");

  let lessonData = controller.getState().lessonData;
  let uploadIndex = !newUpload ? index : (lessonData?.items || []).length;

  if (newUpload) {
    if (!lessonData.items[index].next.includes(uploadIndex))
      lessonData.items[index].next.push(uploadIndex);
    lessonData.items.push({name: newVideoName || "New video", next: []});
    controller.db.collection("Lesson").doc(id).update({items: lessonData.items});
  }
  
  let uploadVideoUrl = `gs://ismor-lesson/${id}/${uploadIndex}`;
  let uploadThumbUrl = `gs://ismor-lesson/${id}/thumb/${uploadIndex}`;
  
  controller.storage.refFromURL(uploadVideoUrl).put(videoBlob)
  .on(app.storage.TaskEvent.STATE_CHANGED, {
    next: (snapshot: any) => {
      setPctComplete(`Uploading video ` + 
        `${snapshot.bytesTransferred === 0 ? "" : (snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2) + "%"}`)
    },
    error: (error: any) => {
      console.log(error);
    },
    complete: async () => {
      controller.storage.refFromURL(uploadThumbUrl).put(thumbBlob)
      .on(app.storage.TaskEvent.STATE_CHANGED, {
        next: (snapshot: any) => {
          setPctComplete(`Uploading thumbnail ` + 
            `${snapshot.bytesTransferred === 0 ? "" : (snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2) + "%"}`)
        },
        error: (error: any) => {
          console.log(error);
        },
        complete: async () => {
          setUploadModal(false);
          setUploading(false);
          setPctComplete("Finished");
        }
      });
    }
  });
}

export const GetAnimation: Handler = async (controller, params) => {
  let {id, setAnimation} = params;
  if (!id || !setAnimation) return console.log("Insufficient params");
  let animationData = (await controller.db.collection("animation").doc(id).get()).data();
  setAnimation(animationData);
}

export const SaveAnimation: Handler = async (controller, params) => {
  let {id, newAnimation, oldAnimation, setAnimation} = params;
  if (!id || !newAnimation || !oldAnimation || !setAnimation) return console.log("Insufficient params");
  setAnimation(newAnimation);
  try {
    await controller.db.collection("animation").doc(id).set(newAnimation);
  } catch {
    console.log("Failed saving to firestore, revert to old value");
    setAnimation({...oldAnimation});
  }
}

export const GetVideoList: Handler = async (controller, params) => {
  let {setVideoList} = params;
  let res = await controller.db.collection("Video").get();
  let videoList = res.docs.map(
    (doc: app.firestore.QueryDocumentSnapshot) => ({id: doc.id, ...doc.data()}));
  setVideoList(videoList);
  // let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  // for (const video of videoList) {
  //   for (const clipId of video.clips) {
  //     const clipDoc = await controller.db.collection("VideoClip").doc(clipId).get();
  //     const clipData = clipDoc.data();
  //     let publishedUrl = clipData.publishUrl.replace(`Video/${clipId}`, `VideoClip/${video.id}/${clipId}`)
  //     await controller.db.collection("VideoClip").doc(clipId).update({publishedUrl});
  //     await dbweb.collection("VideoClip").doc(clipId).update({publishedUrl});
  //   }
  // }
}

export const GetVideoById: Handler = async (controller, params) => {
  let {selectedVideo, setClips} = params;
  let clips = [];
  if ((selectedVideo?.clips || []).length > 0) {
    let clips_: any[] = 
      (await controller.db.collection("VideoClip")
        .where("__name__", "in", selectedVideo?.clips).get()
      )
      .docs.map(
        (doc: app.firestore.QueryDocumentSnapshot) => ({id: doc.id, ...doc.data()})
      );
    if (clips_.length === 0) return console.log("No clips in firestore");

    let storageweb = (controller as any).storageweb as app.storage.Storage;
    clips_ = (await (Promise.all(
      clips_.map(async (clip: any) => { 
        let videoUrl = null;
        let thumbnailUrl = null;
        try {
          videoUrl = await storageweb.refFromURL(clip.publishedUrl).getDownloadURL();
        } catch (e: any) {
          console.log("Can't get videoUrl");
        };
        try {
          thumbnailUrl= await storageweb.refFromURL(clip.publishedUrl+".png").getDownloadURL();
        } catch (e: any) {
          console.log("Can't get thumbnailUrl");
        };
        return ({ ...clip, videoUrl, thumbnailUrl });
      })
    )));
    clips = selectedVideo.clips.map((clipId: any) => clips_.find((clip: any) => clip.id === clipId));
  }
  setClips(clips);
}

export const PublishVideo: Handler = async (controller, params) => {
  let {
    videoList, setVideoList, 
    selectedVideo, setSelectedVideo,
    clips, setClips,
    selectedClip, setSelectedClip,
    newClipIndex, uploadClipData, uploadClipName, uploadClipThumbnailUrl,
    setPublishStatus 
  } = params;

  if (!selectedVideo?.id) return console.log("No video id");
  if (selectedClip === null && newClipIndex === null) return console.log ("No clip");
  if (!uploadClipData) return console.log("No video data");
  if (!uploadClipName) return console.log("No filename");
  if (!uploadClipThumbnailUrl) return console.log("No thumbnail");

  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't publish");

  let storageweb = (controller as any).storageweb as app.storage.Storage;
  if (!storageweb) return console.log("No storageweb. Can't publish");

  setPublishStatus({
    status: "started",
    messages: [ "Start uploading video" ]
  });

  if (newClipIndex !== null) {
    // New clip - create record in collection first.
    let res = await controller.db.collection("VideoClip").add(selectedClip);
    selectedClip.id = res.id;
  } 
  let doc = await controller.db.collection("VideoClip").doc(selectedClip.id).get();
  let docweb = await dbweb.collection("VideoClip").doc(selectedClip.id).get();
  let publishVersion = selectedClip?.nextVersion || 1;
  let publishedUrl = `gs://ismor-xd-publish/VideoClip/${selectedVideo.id}/` 
                      + `${selectedClip.id}/${publishVersion}/${uploadClipName}`;
  let nextVersion = publishVersion + 1;

  const handleComplete = async () => {
    doc.ref.update({
      nextVersion: nextVersion,
      publishedUrl: publishedUrl
    });
    if (!docweb.exists) {
      docweb.ref.set({
        name: selectedClip.name || "New Clip",
        publishedUrl: publishedUrl,
        users: []
      })
    } else {
      docweb.ref.update({publishedUrl});
    }

    selectedClip.videoUrl = await storageweb.refFromURL(publishedUrl).getDownloadURL();
    selectedClip.thumbnailUrl = await storageweb.refFromURL(publishedUrl + ".png").getDownloadURL();

    if (newClipIndex !== null) {
      // New clip -- need to update video accordingly
      clips.splice(newClipIndex, 0, selectedClip);
      let clipIdList = clips.map((clip: any) => clip.id);
      await controller.db.collection("Video").doc(selectedVideo.id)
        .update({clips: clipIdList});
      try {
        await dbweb.collection("Video").doc(selectedVideo.id)
          .update({clips: clipIdList});
      } catch {
        await dbweb.collection("Video").doc(selectedVideo.id).set({
          name: selectedVideo.name,
          clips: clipIdList
        });
      }
      let newSelectedVideo = {...selectedVideo, clips: clipIdList};
      let newVideoList = videoList.map((video: any) => 
        video.id === selectedVideo.id ? newSelectedVideo : video
      );
      setVideoList(newVideoList);
      setSelectedVideo(newSelectedVideo);
    } else {
      clips = clips.map((clip: any) =>
        clip.id === selectedClip.id ? selectedClip : clip
      );
    }

    // Update clips & selectedClip
    setClips([...clips]);
    setSelectedClip(JSON.parse(JSON.stringify(selectedClip)));

    // Close upload dialog
    setPublishStatus(null);
  }

  storageweb.refFromURL(publishedUrl + ".png")
    .put(dataURLToArrayBuffer(uploadClipThumbnailUrl));
  
  storageweb.refFromURL(publishedUrl).put(uploadClipData)
    .on(app.storage.TaskEvent.STATE_CHANGED, {
      next: (snapshot: any) => {
        setPublishStatus({
          status: "uploading",
          messages: [ `Uploading ${(snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2)}%` ]
        });
      },
      error: (error: any) => console.log(error),
      complete: handleComplete
    });  
}


export const UpdateClipInfo: Handler = async (controller, params) => {
  let { selectedClip } = params;
  if (!selectedClip?.id) return console.log("No selectedClip");
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't publish");

  await controller.db.collection("VideoClip").doc(selectedClip.id)
    .update({name: selectedClip.name || "No name"});
  await dbweb.collection("VideoClip").doc(selectedClip.id)
    .update({name: selectedClip.name || "No name"});
}

function dataURLToArrayBuffer(dataURL: string) {
  const base64String = dataURL.split(",")[1]; // Remove "data:image/png;base64,"
  const binaryString = atob(base64String); // Decode Base64
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export const AddNewVideo: Handler = async (controller, params) => {
  let { name } = params;
  if (!name) return console.log("No name for new video");

  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't add new video");

  let ref = await controller.db.collection("Video").add({name, clips: []});
  
  await dbweb.collection("Video").doc(ref.id).set({name, clips: [], users: []});
}
