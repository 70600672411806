import React, { useState } from "react";
import { Dropdown, Form, Button } from "semantic-ui-react";
import ReactTable from 'react-table-6'

const NodeAdmin = (props: any) => {
  
  const [node, setNode] = useState<any>(null);
  
  const newNode = {
    name: "บทบาทใหม่",
    nameEn: "New Role",
    system: "IH",
    color: "#5dbcd2",
    patient: false
  };

  return(
    props.xddata?.nodeMaster ? 
    <div style={{display: "flex"}}>
      <div style={{padding: "20px", flex: 1}}>
        <NodeSelector
          node={node}
          setNode={setNode}
          xdmaster={props.xdmaster}
          xddata={props.xddata}
        />
      </div>
      <div style={{flex: 1}}>
        {node ?
        <div style={{ padding: "20px "}}>
          <NodeEditForm
            xdedit={props.xdedit}
            translate={props.translate}
            onEvent={props.onEvent}
            node={node}
            setNode={setNode}
          />
        </div>
        :
        <div style={{padding: "20px"}}>
          <Button size="mini" color="blue"
            disabled={!props.xdedit}
            onClick={(e: any) => {
              if (!props.xdedit) return;
              setNode({...newNode})
            }}>
            Add New Node
          </Button>
        </div>}
      </div>
    </div>
    :
    <></>
  )
}

export default NodeAdmin;

export const NodeEditForm = (props: any) => {
  return(
    <Form>
      {/* <Form.Group> */}
        <Form.Input 
          label="Name" size="mini" value={props.node?.name}
          disabled={!props.xdedit}
          onChange={(e: any, v: any) => props.setNode({...props.node, name: v.value})}
        />
        <Form.Input 
          label="NameEN" size="mini" value={props.node?.nameEn || ""}
          disabled={!props.xdedit}
          onChange={(e: any, v: any) => props.setNode({...props.node, nameEn: v.value})}
        />
        <Form.Input 
          label="System" size="mini" value={props.node?.system}
          disabled={!props.xdedit}
          onChange={(e: any, v: any) => props.setNode({...props.node, system: v.value})}
        />
        <Form.Input
          label="Color" size="mini" value={props.node?.color || ""}
          disabled={!props.xdedit}
          onChange={(e: any, v: any) => props.setNode({...props.node, color: v.value})}
        />
        <div 
          style={{
            width: "60px", height: "60px",
            backgroundColor: props.node?.color || "white"
          }}>
          </div>
      {/* </Form.Group> */}
      <Form.Checkbox
        label="Patient" size="mini" checked={props.node?.patient}
        onClick={(e: any) => props.setNode({...props.node, patient: !props.node?.patient})}
      />
      <Button size="mini" color="red"
        onClick={(e: any) => props.setNode(null)}>
        Cancel
      </Button>
      <Button size="mini" color="green" 
        disabled={!props.xdedit}
        onClick={(e: any) => {
          if (!props.xdedit) return
          e.preventDefault();
          props.onEvent({message: "SaveNode", params: {node: {...props.node}}});
          props.setNode(null);
        }}>
        Save
      </Button>
    </Form>
  )
}

export const NodeSelector = (props: any) => {

  const [systemFilter, setSystemFilter] = useState<any>("ALLSYSTEM");

  const systemOptions = [
    { key: "ALLSYSTEM", value: "ALLSYSTEM", text: "All Systems"}
  ].concat(
    Array.from(
      new Set(Object.values(props.xddata?.nodeMaster || {})
              .map((node: any) => node.system))
    ).map((systemName: string) => 
      ({key: systemName, value: systemName, text: systemName}))
  );

  const tableData = Object.entries(props.xddata.nodeMaster || {})
    .sort((a: [string, any], b:[string, any]) => 
      (Number.parseInt(a[0]) < Number.parseInt(b[0]) ? 1: -1))
    .filter((data: [string, any]) => (
        systemFilter === "ALLSYSTEM" ? true
        : data[1].system === systemFilter))
    .map((data: [string, any]) => (
      { 
        id: data[0], ...data[1], 
        // patient: data[1].patient.toString() 
      }
    ));

    return(
      <div>
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          <select
            style={{width: "200px"}}
            value={systemFilter}
            onChange={(e: any) => {
              props.setNode(null);
              setSystemFilter(e.target.value)
            }}>
            {systemOptions.map((option: any, index: number) => (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <ReactTable
          getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: (e: any) => {
                if (props.node?.id && props.node.id === rowInfo?.original?.id)
                  props.setNode(null);
                else
                  props.setNode(rowInfo.original);
              },
              style: {
                backgroundColor: props.node?.id && props.node.id === rowInfo?.original?.id 
                                  ? "#cccccc" : "white"
              }
            }
          }}
          getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              style: {
                textAlign: column.Header === "Name" ? "left" : "center"
              }
            }
          }}
          pageSize={10}
          columns={[
            { Header: "Name", accessor: "name" },
            { Header: "NameEN", accessor: "nameEn" },
            { Header: "System", accessor: "system" },
            { Header: "Color", accessor: "color",
              Cell: (cellInfo, column)  => 
                <div style={{display: "flex", textAlign: "center", justifyContent: "space-around"}}>
                  <div style={{flex: 1}}></div>
                  <div style={{backgroundColor: cellInfo.value}}>{"\u00a0".repeat(15)}</div>
                  <div style={{width: "75px", textAlign: "left", marginLeft: "10px"}}>{cellInfo.value}</div>
                  <div style={{flex: 1}}></div>
                </div>
            },
            { Header: "Patient", accessor: "patient", 
              Cell: (cellInfo, column) => cellInfo.value.toString()
            },
          ]}
          data={tableData}
        />
      </div>)
}
