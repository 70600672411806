import { useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import { loadPythonWorker } from "ismor-lib/utils/Python";
import { monthKeys, MenuItem } from "./Utils"
import StaffList from "./StaffList";
import ByStaff from "./ByStaff";
import ByYear from "./ByYear";
import AddDetail from "./AddDetail";
import EditStaffForm from "./EditStaffForm";
import Extra from "./Extra";
import Analysis from "./Analysis";
import XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import { query } from "ismor-lib/utils/DuckLib";


export default function Staff(props: any) {
  const [mode, setMode] = useState("By Year");
  const [subMode, setSubMode] = useState<any>(null);
  const [selectedStaff, setSelectedStaff] = useState<any>(null);
  const [staffDetail, setStaffDetail] = useState<any>(null);
  const [pythonLoaded, setPythonLoaded] = useState(false);
  const [pythonError, setPythonError] = useState<any>(null);

  const python = useRef<any>(null);

  // Use Effect ---------------------------------------------------------------------------------
  useEffect(() => { initPython() }, []);

  useEffect(() => {
    if (props.adminLoggedin)
      props.onEvent({message: "GetStaffData", params: {}});
  }, [props.adminLoggedin]);

  useEffect(() => {
    if (pythonError === null) return;
    console.log(pythonError);
    setPythonError(null);
  }, [pythonError]); 

  useEffect(() => {
    if (selectedStaff) {
      let detail = Object.values(props.monthDetail).map((item: any) => item?.[selectedStaff]).filter((item: any) => item)
      const detailDisplay = detail.reduce((acc: any, cur: any) => 
        {
          const year = cur.ym.toString().substring(0, 4);
          const month = cur.ym.toString().substring(4);
          if (!(year in acc["year"])) acc["year"][year] = {};
          if (!(year in acc[month])) acc[month][year] = {}
          acc[month][year] = cur.components;
          return acc;
        }, 
        Object.fromEntries([["year", {}]].concat(
          monthKeys.map((monthKey: string) => [monthKey, {}])))
      );
      setStaffDetail(detailDisplay);
    }
  }, [selectedStaff]);

  const initPython = async () => {
    python.current = await loadPythonWorker("Staff", setPythonLoaded, setPythonError, false, `
    import pandas
    True
    `);
  };

  const downloadBackup = async (e: any) => {
    const zip = JSZip();

    // Excel: staff_month_detail
    const workbook = XLSX.utils.book_new();
    
    let detailData = await query(`from staff_month_detail`, props.duckCon)
    let detailLongData = await query(`from staff_month_detail_long`, props.duckCon)
    let detailWideData = await query(`
      pivot staff_month_detail_long 
      on ym 
      using sum(value) 
      group by category, staff
      order by category, staff`, 
      props.duckCon);

    let detailWideAoA = [
        [ "staff", 
          "category", 
          ...Object.keys(detailWideData[0])
            .filter((key: string) => !["staff", "category"].includes(key))
            .sort((a: any, b: any) => a < b ? -1 : 1)
        ]
      ].concat(
      detailWideData.map((row: any) => (
        [
          row.staff,
          row.category,
          ...Object.entries(row)
            .filter((entry: any) => !["staff", "category"].includes(entry[0]))
            .sort((a: any, b: any) => a[0] < b[0] ? -1 : 1)
            .map((entry: any) => entry[1])
        ]
      )));

    const detailSheet = XLSX.utils.json_to_sheet(detailData);
    const detailLongSheet = XLSX.utils.json_to_sheet(detailLongData);
    const detailWideSheet = XLSX.utils.aoa_to_sheet(detailWideAoA);

    XLSX.utils.book_append_sheet(workbook, detailSheet, "Detail");
    XLSX.utils.book_append_sheet(workbook, detailLongSheet, "DetailLong");
    XLSX.utils.book_append_sheet(workbook, detailWideSheet, "DetailWide");
    
    let wbout = XLSX.write(workbook, {bookType: "xlsx", bookSST: true, type: "binary"})
    zip.file("staff_month_detail.xlsx", wbout, {binary: true});
    
    // JSON: raw data
    for (const item of ["staffDict", "dividendDict", "letterDict", "staffProfile", "monthDetail"]) 
    {
      zip.file(`${item}.json`, 
        new Blob([JSON.stringify(props[item], null, 1)], 
        {type: "application/json"}));
    }

    // Zip
    zip.generateAsync({type: "blob", compression: "DEFLATE"})
    .then((content: any) => saveAs(content, "staff_data.zip"));;
  }

  // Render -------------------------------------------------------------------------------------
  return(
    <div>
      <div style={{display: "flex", borderBottom: "solid #eeeeee 1px", alignItems: "center"}}>
        <MenuItem name="Staff List" mode={mode} setMode={setMode} />
        {/* <MenuItem name="Add Staff" mode={mode} setMode={setMode} /> */}
        <MenuItem name="By Year" mode={mode} setMode={setMode} />
        {/* <MenuItem name="Add Detail" mode={mode} setMode={setMode} /> */}
        {props.staffplus && false &&
        <>
          <MenuItem name="Analysis" mode={mode} setMode={setMode} />
          {/* <MenuItem name="Extra" mode={mode} setMode={setMode} /> */}
        </>
        }
        <div style={{flex: 1}}></div>
        <Icon name="download" color="blue" onClick={downloadBackup} />
      </div>
      <div style={{padding: "5px", height: "91vh", overflow: "auto"}}>
        {mode === "Staff List" && !subMode ?
        <StaffList
          onEvent={props.onEvent}
          setSubMode={setSubMode}
          setSelectedStaff={setSelectedStaff}
          staffProfile={props.staffProfile}
        />
        : mode === "Staff List" && subMode === "By Staff" && staffDetail ?
        <ByStaff
          onEvent={props.onEvent}
          setSubMode={setSubMode}
          selectedStaffProfile={props.staffProfile?.[selectedStaff]}
          staffDetail={staffDetail}
        />
        : mode === "Add Staff"?
        <EditStaffForm
          onEvent={props.onEvent}
        />
        : mode === "Add Detail" ?
        // to change ----------------------------
        <AddDetail
          onEvent={props.onEvent}
          staffDict={props.staffDict}
        />
        : mode === "By Year" ?
        // to change ----------------------------
        <ByYear
          onEvent={props.onEvent}
          staffplus={props.staffplus}
          staffDict={props.staffDict}
          staffProfile={props.staffProfile}
          monthDetail={props.monthDetail}
          dividendDict={props.dividendDict}
          letterDict={props.letterDict}
          triggerStaff={props.triggerStaff}
        />
        
        : mode === "Extra"?
        <Extra
          onEvent={props.onEvent}
        />
        : mode === "Analysis" ?
        (props.staffplus && pythonLoaded?
        <Analysis
          onEvent={props.onEvent}
          duckCon={props.duckCon}
          duckLoaded={props.duckLoaded}
          python={python}
          pythonLoaded={pythonLoaded}
          pythonError={pythonError}
          staffProfile={props.staffProfile}
          monthDetail={props.monthDetail}
        />
        :
        <></>)
        :
        <></>
        }      
      </div>
    </div>
  )
}
