import { useEffect, useRef, useState } from "react";
import { Checkbox, Modal, Input, Button } from "semantic-ui-react";

export default function VD(props: {
  forClient: boolean,
  debug: boolean,
  vdedit: boolean,
  onEvent: any,
  setProp: any,
  clientUserDict: Map<string, any>,
  clientContactDict: Map<string, any>
}) {
  let [videoList, setVideoList] = useState<any[]>([]);
  let [selectedVideo, setSelectedVideo] = useState<any>(null);
  let [clips, setClips] = useState<string[]>([]);
  let [selectedClip, setSelectedClip] = useState<any>(null);
  let [selectedClipIndex, setSelectedClipIndex] = useState<number | null>(null);
  let [newClipIndex, setNewClipIndex] = useState<number | null>(null);
  let [newClipName, setNewClipName] = useState("");
  let [publishStatus, setPublishStatus] = useState<any>(null);
  let [userList, setUserList] = useState<any[]>([]);
  let [openShare, setOpenShare] = useState(false);
  let [message, setMessage] = useState<any>(null);
  let [selectedIndex, setSelectedIndex] = useState<number|null>(null);
  let [videoProgress, setVideoProgress] = useState(0);
  let [uploadClipName, setUploadClipName] = useState<string | null>(null);
  let [uploadClipData, setUploadClipData] = useState<any>(null);
  let [uploadClipThumbnailUrl, setUploadClipThumbnailUrl] = useState("");
  let [showDetails, setShowDetails] = useState(true);
  let videoRef = useRef<HTMLVideoElement>(null);
  let videoContainerRef = useRef<HTMLDivElement>(null);
  let [height, setHeight] = useState(100);
  let [width, setWidth] = useState(100);
  let [newVideoDialog, setNewVideoDialog] = useState(false);
  let [newVideoName, setNewVideoName] = useState("");

  useEffect(() => {
    props.onEvent({message: "GetVideoList", params: {setVideoList}});
    props.onEvent({message: "GetClientUser", params: {}});
  }, []);

  useEffect(() => {
    // if (videoList.length > 0) {
    //   setSelectedVideo(videoList[0]);
    // }
  }, [videoList]);

  useEffect(() => {
    if (!selectedVideo || !selectedVideo.id) return;
    setVideoProgress(0);
    setClips([]);
    setSelectedClip(null);
    setSelectedClipIndex(null);
    props.onEvent({message: "GetVideoById", params: {selectedVideo, setClips}});
  }, [selectedVideo]);

  useEffect(() => {
    adjustVideoSize();
  }, [selectedClip]);

  useEffect(() => {
    if (clips && clips.length > 0 && selectedClip === null) {
      setSelectedClip(clips[0]);
      setSelectedClipIndex(0);
    }
  }, [clips]);

  useEffect(() => {
    if (!props.clientUserDict || !props.clientContactDict) return;
    let combinedList = Array.from(
      new Set(
        Array.from(props.clientUserDict.keys())
        .concat(Array.from(props.clientContactDict.keys())))
    );
    setUserList(combinedList.map((id: string) => (
      {
        id, 
        login: props.clientUserDict.has(id), 
        detail: props.clientUserDict.get(id),
        contact: props.clientContactDict.get(id) || null
      }
    )));
  }, [props.clientUserDict, props.clientContactDict]);

  const addNewVideo = async () => {
    await props.onEvent({message: "AddNewVideo", params: { name: newVideoName }});
    setNewVideoName("");
    setNewVideoDialog(false);
    await props.onEvent({message: "GetVideoList", params: { setVideoList }});
  }

  const adjustVideoSize = () => {
    const container = videoContainerRef?.current?.getBoundingClientRect();
    if (container) {
      setHeight(container?.height);
      setWidth(container?.width);
    } else {
      console.log("No container");
    }
  }

  const processUploadedFile = async (file: File) => {
    setPublishStatus({status: "reading", messages: ["Reading file..."]})
    const data: ArrayBuffer = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
    setUploadClipName(file.name);
    setUploadClipData(data);
    let uploadObjectUrl = URL.createObjectURL(file);
    await new Promise((resolve, reject) => {
      let video = document.createElement("video");
      video.src = uploadObjectUrl;
      video.load();
      video.onloadedmetadata = () => {
        video.currentTime = 0;
      }
      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imgURL = canvas.toDataURL("image/png");
        setUploadClipThumbnailUrl(imgURL);
        resolve(true);
      }
    });
    setPublishStatus({status: "previewing", messages: ["Preview file"]})
  }

  const doUploadVideo = async () => {
    await props.onEvent({
      message: "PublishVideo",
      params: {
        videoList, setVideoList,
        selectedVideo, setSelectedVideo,
        clips, 
        setClips,
        selectedClip: newClipIndex === null ? selectedClip : {name: newClipName}, 
        setSelectedClip,
        newClipIndex, uploadClipData, uploadClipName, uploadClipThumbnailUrl,
        setPublishStatus
      }
    });
    setNewClipName("New Clip");
    setNewClipIndex(null);
  }

  return(
    <div style={{height: "92vh"}}>
      <div style={{backgroundColor: "lightgreen", display: "flex"}}>
        {selectedVideo !== null && 
        <>
          <div style={{marginRight: "10px", cursor: "pointer"}} 
            onClick={(_: any) => setSelectedVideo(null)}>
            {`<<`}
          </div>
          <div>
            <button onClick={(e: any) => videoRef.current?.play()}>Play</button>
            <button onClick={(e: any) => videoRef.current?.pause()}>Pause</button>
            <button 
              onClick={(e: any) => {
                if (!videoRef.current) return;
                videoRef.current?.pause();
                videoRef.current.currentTime = 0;
              }}>
              Back to Start
            </button>
            <button 
              onClick={(e: any) => {
                if (!videoRef.current) return;
                if (videoRef.current.currentTime > 10)
                  videoRef.current.currentTime -= 10;
                else 
                  videoRef.current.currentTime = 0;
              }}>
              &lt;&lt;
            </button>
            <button 
              onClick={(e: any) => {
                if (!videoRef.current) return;
                if (videoRef.current.currentTime < videoRef.current.duration - 10)
                  videoRef.current.currentTime += 10;
                else 
                  videoRef.current.currentTime = videoRef.current.duration;
              }}>
              &gt;&gt;
            </button>
            <button
              style={{display: "none"}}
              onClick={(_: any) => {
                setShowDetails(!showDetails);
              }}>
              Details
            </button>
          </div>
          {!props.forClient &&
          <button
            disabled={!props.debug}
            onClick={(_: any) => {
              setUploadClipThumbnailUrl(null);
              if (selectedClipIndex !== null) {
                setNewClipIndex(selectedClipIndex + 1)
              } else {
                setNewClipIndex(clips.length);
              }
              setPublishStatus({status: "modal", messages: []});
            }}>
            + Clip After
          </button>}
          {props.vdedit &&
          <div 
            style={{cursor: "pointer", position: "relative"}}>
            <button
              onClick={() => setOpenShare(!openShare)}>
              Sharing
            </button>
            {openShare &&
            <div 
              style={{
                position: "absolute", left: 0, top: 20, border: "solid grey 1px",
                height: "600px", width: "600px", overflow: "scroll", backgroundColor: "white",
                display: "grid", gridTemplateColumns: "1fr 2fr", zIndex: 1000
              }}>
              {userList
              .sort((a: any, b: any) => a.id < b.id ? -1 : 1)
              .flatMap((user: any, index: number) => {
                let {name, organization, role} = user?.detail;;
                return ([
                  <div key={index * 3 + 1} style={{borderBottom: "solid #cccccc 1px", display: "flex"}}>
                    <Checkbox 
                      key={index * 3} style={{marginRight: "5px"}} 
                      checked={(user?.contact?.videoList || []).includes(selectedVideo.id)}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        if (message) return;
                        setSelectedIndex(index)
                        props.onEvent({message: "SetClientContactPermission", params: {
                          email: user.id,
                          data: {
                            videoList: (user?.contact?.videoList || []).includes(selectedVideo.id) ?
                                      (user?.contact?.videoList || []).filter((videoId: any) => videoId !== selectedVideo.id)
                                      : Array.from(new Set((user?.contact?.videoList || []).concat([selectedVideo.id])))
                          },
                          setMessage,
                          videoList: videoList,
                        }});
                      }}
                    />
                    <div>{user.id}</div>
                  </div>,
                  <div key={index * 3 + 2} style={{borderBottom: "solid #cccccc 1px"}}>
                    <div>{`${name || "(blank)"} / ${organization || "(blank)"} / ${role || "(blank)"}`}</div>
                    {selectedIndex === index && message && 
                    <div style={{backgroundColor: "lightyellow"}}>
                      {message.map((item: string, messageIndex: number) => 
                      <div key={messageIndex} style={{color: "red"}}>{item}</div>)}
                    </div>}
                  </div>
                ])
              })}
            </div>}
          </div>}
        </>}
      </div>

      {selectedVideo === null ?
      <>
        <div 
          style={{cursor: "pointer", display: props.debug ? "block" : "none"}} 
          onClick={(e: any) => { 
            setNewVideoDialog(true); 
          }}>
          + Add New Video
        </div>
        <ul style={{width: "100%"}}>
          {videoList
          .sort((a: any, b: any) => a?.name < b?.name ? -1 : 1)
          .map((video: any, index: number) => (
            <li 
              key={index}
              style={{
                padding: "5px",
                cursor: "pointer",
                backgroundColor: selectedVideo?.id === video.id ? "#eeeeee" : "white"
              }}
              onClick={(e: any) => setSelectedVideo(video)}>
              {video.name}
            </li>
          ))}
        </ul>
      </>
      :

      <div 
        style={{
          height: "100%", 
          display: "grid", gridTemplateRows: "1% 19% 80%"
        }}>
        {/* Progress bar --------------------------------------- */ }
        <div style={{width: "100%", backgroundColor: "lightgrey"}}>
          <div style={{backgroundColor: "red", width: `${videoProgress}%`, height: "5px"}}>
          </div>
        </div>

        { // Clip List -----------------------------------------------------
        showDetails &&
        <div 
          style={{
            display: "flex",
            borderBottom: "solid grey 1px", 
            overflowX: "scroll",
          }}>
          {clips.length > 0 ?
           clips.map((clip: any, clipIndex: number) => { 
            let hasNewClip = newClipIndex !== null && clipIndex + 1 === newClipIndex;
            return(
              <div 
                key={clipIndex}
                style={{
                  position: "relative",
                  cursor: "pointer",  
                  height: "100%", width: "auto",
                  // width: hasNewClip ? "400px" : "200px", 
                  display: "flex", 
                  margin: "2px",
                  border: selectedClip?.id === clip?.id && !hasNewClip
                                    ? "solid red 2px" : "none",
                  overflow: "clip"
                }}
                onClick={(_: any) => {
                  setSelectedClip(clip);
                  setSelectedClipIndex(clipIndex);
                  setNewClipIndex(null);
                }}
                >
                <div 
                  style={{position: "absolute", top: 0, left: 0}}
                  >
                  {clip.name}
                </div>
                <img src={clip.thumbnailUrl || '/blankscreen.png'} />
              </div>
            )
          })
          : 
          <div></div>
          }
        </div>}

        {/* Player --------------------------------------- */ }
        <div style={{flex: 1, display: "grid", gridTemplateColumns: "80% 20%"}}>
          <div
            ref={videoContainerRef}>
            {selectedClip && selectedClip.videoUrl && newClipIndex === null &&
            <video 
              ref={videoRef}
              src={selectedClip.videoUrl} 
              onContextMenu={(e: any) => {
                e.preventDefault();
                return false
              }}
              onTimeUpdate={(e: any) => {
                if (!videoRef.current) return;
                setVideoProgress(100 * (videoRef.current.currentTime / videoRef.current.duration))
              }}
              // controls 

              style={{maxWidth: `${width}px`, maxHeight:`${height}px`}}
              preload="metadata"
            />}
          </div>
          
          {// Side Panels -------------------------------------------------
          showDetails && 
          <div style={{borderLeft: "solid grey 1px", padding:"5px"}}>
            {
            !selectedClip || newClipIndex !== null ?
            <></>

            :props.forClient ?
            <div>{`Title: ${selectedClip?.name || ""}`}</div>

            :
            <>
              <div>
                <input
                  disabled={!props.debug}
                  type="text"
                  value={selectedClip?.name || ""}
                  onChange={(e: any) => {
                    setSelectedClip({
                      ...selectedClip,
                      name: e.target.value
                    });
                    let clips_ = clips.map((clip: any, index: number) => 
                                          index === selectedClipIndex ? 
                                          {...clip, name: e.target.value} 
                                          : clip);
                    setClips(clips_);

                  }}
                />
                <button
                  disabled={!props.debug}
                  onClick={(e: any) => {
                    props.onEvent({message: "UpdateClipInfo", params: {selectedClip}})
                  }}>
                  Save
                </button>
              </div>
              <button 
                disabled={!props.debug}
                onClick={(e: any) => {
                  setUploadClipThumbnailUrl(null);
                  setPublishStatus({status: "modal", messages: []});
                }}>
                Upload new file
              </button>
            </>}
          </div>}
        </div>
      </div>
      }

      <Modal
        size="small"
        open={newVideoDialog}
        closeOnDimmerClick
        onClose={() => setNewVideoDialog(false)}
        >
        <div 
          style={{
            height: "400px", display: "flex", flexDirection: "column", 
            justifyContent: "center", alignItems: "center"
          }}>
          <h3>Add New Video</h3>
          <Input 
            style={{ width: "80%" }} 
            value={newVideoName} 
            onChange={(e: any) => setNewVideoName(e.target.value)} placeholder="ชื่อวิดีโอ" 
          />
          <div style={{display: "flex", marginTop: "20px"}}>
            <Button size="mini" color="red" onClick={(e: any) => { setNewVideoName(""); setNewVideoDialog(false);}}>
              Cancel
            </Button>
            <Button 
              size="mini" color="green"
              onClick={(e: any) => { addNewVideo(); }}>
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        size="small"
        closeOnDimmerClick
        onClose={()=> {
          setPublishStatus(null);
          setNewClipIndex(null);
        }}
        open={publishStatus !== null}>
        <div 
          style={{
            minHeight: "300px", overflowY: "auto", textAlign: "center", padding: "20px", 
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
          }}>
          <div>
            {newClipIndex === null ? "อัพไฟล์ลงในคลิปเดิม" : "เพิ่มคลิปใหม่"}
          </div>
          {(publishStatus?.messages || [])
          .map((message: string, index: number) => (
            <div key={index}>{message}</div>
          ))}
          <div>
            <input 
              disabled={!props.debug}
              type="file"
              onClick={(e: any) => e.target.value = null}
              onChange={async (e: any) => {
                if (e.target?.files?.[0]) {
                  let file = e.target.files[0];
                  processUploadedFile(file);
                }
              }}
            />
          </div>
          <img width="100%" src={uploadClipThumbnailUrl} />
          {newClipIndex !== null &&
          <Input
            style={{width: "80%", margin: "10px 0 10px 0"}}
            placeholder="ชื่อคลิปใหม่"
            value={newClipName}
            onChange={(e: any) => {
              setNewClipName(e.target.value);
            }}
          />}
          {publishStatus?.status === "previewing" && (newClipName !== "" || newClipIndex === null) &&
          <button
            onClick={(_: any)=> {
              doUploadVideo();
            }}>
            Upload
          </button>}
        </div>
      </Modal>
    </div>
  )
}
