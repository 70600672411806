import { WasmHandler } from 'react-lib/frameworks/WasmController'
import { Step, ExchangeDiagramData, NodeDetail, XdCase } from "./TypeDef";
import XLSX from "xlsx";
import { Loc } from './XdDetail';
import { NodeDetailDocItem } from './TypeDef';
import SpecRender from "./SpecRender";
import moment from "moment";
import { tableFromJSON } from 'apache-arrow';
import { AsyncDuckDB, AsyncDuckDBConnection } from '@duckdb/duckdb-wasm';
import app from 'firebase/compat/app';

const defaultXdId = "cmpo0PxeIOc72KvKU3yw";

export type State = {
  xdId?: string | null,
  xddata?: ExchangeDiagramData,
  xdmaster?: any,
  
  docInfo?: NodeDetailDocItem[],
  trigger?: boolean,
  xdSpecProgress?: string[],
  xddataInitialLoad?: boolean,
  xdCases?: XdCase[],
  xdProblemDict?: any,
  xdTestList?: any[],
  xdJourneyDataState?: string,
}

export const StateInitial: State = {
  xdId: null,
  xddata: {
    name: "",
    cases: [],
    nodes: [],
    nodeMaster: {},
    steps: {},
    sections: [],
    sequence: [],
    edges: [],
  },
  xdmaster: {},
  docInfo: [],
  trigger: false,
  xdSpecProgress: [],
  xddataInitialLoad: false,
  xdCases: [],
  xdProblemDict: {},
  xdTestList: [],
  xdJourneyDataState: "init",
}

export type Event =
  { message: "LoadXD", params: any }
  | { message: "GetXDMaster", params: {} }
  | { message: "SaveXD", params: any }
  | { message: "AddXD", params: any }
  | { message: "ChangeXD", params: any }
  | { message: "AddSection", params: { sectionIndex: number } }
  | { message: "EditSection", params: { name: string } }
  | { message: "MoveSection", params: { fromSection: number, toSection: number } }
  | { message: "AddXdStep", params: { step: Step, stepIndex: number, sectionIndex: number } }
  | { message: "SaveXdStep", params: { step: Step } }
  | { message: "ModifyStepRole", params: { loc: Loc, nodeDetail: NodeDetail } }
  | { message: "MoveXdStep", params: { fromStep: any, toStep: any } }
  | { message: "SaveNode", params: {} }
  | { message: "AddNode", params: { data: any } }
  | { message: "SwapNode", params: { indices: [number, number] } }
  | { message: "MoveNode", params: { fromNode: number, toNode: number } }
  | { message: "MoveEdge", params: { fromEdgeIndex: number, toEdgeIndex: number } }
  | { message: "ExportGridToExcel", params: {} }
  | { message: "SwapStep", 
      params: { indices: [number, number] } }
  | { message: "AddXdDependOn", 
      params: { from: string, to: string } }
  | { message: "RemoveXdDependOn",
      params: { from: string, to: string } }
  | { message: "ToggleXdDependOnOptional",
      params: { from: string, to: string } }
  | { message: "SaveNodeDetailDoc", 
      params: { doc: NodeDetailDocItem[], stepId: string, loc: Loc }}
  | { message: "GetNodeDetailDoc",
      params: { loc: Loc }}
  | { message: "DownloadSpec", 
      params: {
        sections: any[], view: any, layout: any, xddata: any, cols: any[],
        roleList: any[], role: any, webviewReady: boolean }}
  | { message: "GetXdCaseList", params: { xdId: string } }
  | { message: "AddXdCase", params: { name: string } }
  | { message: "EditXdCase", params: { caseId: string, name: string } }
  | { message: "AddXdCaseSeq", 
      params: { caseId: string, xdId: string, stepId: string } }
  | { message: "DeleteXdCaseSeq", 
      params: { caseId: string, seqIndex: number } }
  | { message: "AddOrEditXdProblem"
      params: {} }
  | { message: "AddXdTest", params: {} }
  | { message: "GetXdTestList", params: { done: boolean } }
  | { message: "CloseXdTest", params: { testId: string } }
  | { message: "UpdateXdTest", params: { testId: string } }
  | { message: "GetXdTestSummary", params: { xdId: string } }
  | { message: "SwapXdCaseSeq", params: { seqToMove: number, seqToMoveTo: number } }  
  | { message: "PrepareXdJourneyData", 
      params: { xddata: any, setJourneyDataState: (dataState: string) => {} } }
  | { message: "XdPublish", params: {setPublishDialog: (params: any) => {}} }
  | { message: "GoogleTranslate", params: {text: string} }

export type Data = {
  unsubscribeXd?: any,
  unsubscribeXdStep?: any,
  unsubscribeXdCase?: any,
  unsubscribeXdTest?: any,
  unsubscribeXdProblem?: any,
  duck?: AsyncDuckDB,
  duckCon?: AsyncDuckDBConnection,
  python?: any,
  FASTAPI?: string,
}

export const DataInitial: Data = {
  unsubscribeXd: () => {},
  unsubscribeXdStep: () => {},
  unsubscribeXdCase: () => {},
  unsubscribeXdTest: () => {},
  unsubscribeXdProblem: () => {},
}

export type Handler = WasmHandler<State, Event, Data>

const thumbSize = 300;

export const GetXDMaster: Handler = async (controller, params) => {
  let xdmaster = (await controller.db.collection("cache").doc("xd2").get()).data() || {};
  let xdCases = (await controller.db.collection("xdcase").get())
    .docs.map((doc: any) => ({id: doc.id, ...doc.data()}));
  controller.setState({ xdmaster, xdCases });
}

export const AddXD: Handler = async (controller, params) => {
  const state = controller.getState();
  let newXdData: any = {
    name: params.name,
    nodes: ["0"],
    nodeMaster: {
      "0": {
        color: "pink",
        name: "ผู้ป่วย",
        patient: true,
        system: "IH"
      }
    },
    sections: [],
    sequence: [],
    edges: [],
  }
  
  // Create new xd
  const newXd = await controller.db.collection("xd2").add(newXdData);
  const newXdOption = { id: newXd.id, name: params.name };

  // Create first step
  let firstStepData: any = {
    xd: newXd.id,
    description: "First step",
    dependOn: [],
    dependOnOptional: [],
    nodeDetail: {},
  }
  const firstStep = await controller.db.collection("xd2").doc(newXd.id).collection("xdstep").add(firstStepData);
  firstStepData = {id: firstStep.id, ...firstStepData};

  // Add first step back to xd
  const initialSectionData = [{
    name: "First Section",
    steps: [firstStep.id]
  }];
  await controller.db.collection("xd2").doc(newXd.id).update({
    sections: initialSectionData
  });

  // Update state
  controller.setState({
    xdId: newXd.id,
    xddata: {
      ...newXdData,
      sections: initialSectionData,
      steps: {[firstStep.id]: firstStepData}
    },
    xdmaster: {
      ...state.xdmaster,
      allXd: [...state.xdmaster.allXd, newXdOption]
    },
  });
  
  // Retrieve latest, add newXd, and save
  let allXd = (await 
    controller.db.collection("cache").doc("xd2").get())?.data()?.allXd || [];
  allXd = [...allXd, newXdOption];  
  await controller.db.collection("cache").doc("xd2").update({allXd});

  // Refresh allXd again (in case other(s) added before newXd)
  controller.setState({
    xdmaster: {
      ...state.xdmaster,
      allXd
    },
  });

  // Update preferences
  await controller.db.collection("preferences")
    .doc(controller.user.email)
    .update({xd: newXd.id});
}

const GetXdId: Handler = async (controller) => {
  let xdId = controller.user.email ? 
    (await 
      controller.db.collection("preferences")
      .doc(controller.user.email)
      .get()
    )?.data()?.xd || null
    : null

  if (xdId === null)
    xdId = defaultXdId;
  return xdId
}

export const LoadXD: Handler = async (controller, params) => {
  let xdId = await GetXdId(controller);

  let xd = (await 
    controller.db.collection("xd2")
    .doc(xdId)
    .get()
  ).data() || StateInitial.xddata;

  // Unsubscribe
  controller.data.unsubscribeXd();
  controller.data.unsubscribeXdStep();
  controller.data.unsubscribeXdCase();
  controller.data.unsubscribeXdProblem();
  controller.data.unsubscribeXdTest();

  // Add snapshot listener for xd
  controller.data.unsubscribeXd = controller.db.collection("xd2")
    .where("__name__", "==", xdId)
    .onSnapshot({
      next: (querySnapshot: any) => {
        querySnapshot
        .docChanges()
        .forEach((change: any) => {
          if (change.type === "modified") {
            PopulateXD(
              controller, 
              {xdId, xd: change.doc.data(), onlyXd: true}
            );       
          }
        })
      },
      error: () => {}
    });
  
  // Add snapshot listener for xdstep
  controller.data.unsubscribeXdStep = controller.db.collection("xd2").doc(xdId).collection("xdstep")
    .where("xd", "==", xdId)
    .onSnapshot({ 
      next: (querySnapshot: any) => {
        let {xddataInitialLoad, xddata} = controller.getState();
        let {steps, ...refreshXd} = xddata as ExchangeDiagramData;
        let nonInitialCount = 0;
        for (const change of querySnapshot.docChanges()) {
          steps[change.doc.id] = { id: change.doc.id, ... change.doc.data() }; 
          if (change.doc.oldIndex != -1) nonInitialCount += 1;
        }
        // Do nothing if everything is initial load (already handled)
        if (nonInitialCount === 0) return;
        PopulateXD(
          controller, 
          { xdId, xd: refreshXd, steps }
        );
      },
      error: () => {}
    });

  PopulateXD(controller, {xdId, xd});
}

export const PopulateXD: Handler = async (controller, params) => {
  if (!params.xdId || !params.xd) return;
  
  let steps: {[id: string]: Step};
  if (params.steps) {
    // steps are modified. use steps in params
    steps = {
      ...(controller.getState()?.xddata?.steps || {}), 
      ...params.steps
    };
  } else if (params.onlyXd) {
    // only Xd is loaded, no need to load all steps
    // console.log("onlyXd");
    steps = controller.getState().xddata?.steps!;
  } else {
    // Initial load. Load xd and all xdsteps
    // console.log("all");
    const stepList = (await 
      controller.db.collection("xd2").doc(params.xdId).collection("xdstep")
      .where("xd", "==", params.xdId)
      .get()
    ).docs.map((doc: any) => ([doc.id, {id: doc.id, ...doc.data()}]));
    steps = Object.fromEntries(stepList);
  }

  // Populate edges from steps. Set refresh = true to populate
  let edges =
    params.xd.sections.flatMap((section: any, sectionIndex: number) => (
      section.steps.flatMap((stepId: any) => {
        const step = steps[stepId];
        return step.dependOn
          .filter((dependOnId: string) => 
            !(step.dependOnOptional && step.dependOnOptional.includes(dependOnId)))
          .map((to: string) => ({from: step.id, to: to, optional: false}))
          .concat(
            (step.dependOnOptional || [])
              .map((to: string) => ({from: step.id, to: to, optional: true})))
      })
    ));

  let cacheEdges = Object.assign([], params.xd.edges);
  const length1 = cacheEdges.length;
  cacheEdges = cacheEdges.filter((cacheEdge: any) => 
    edges.filter((edge: any) => cacheEdge.from === edge.from && cacheEdge.to === edge.to).length > 0
  );
  const length2 = cacheEdges.length;
  if (length1 !== length2)
    console.log(`remove ${length1 - length2} edge(s) from cache not contained in step data`);
  for (const edge of edges) {
    if (cacheEdges.filter((cacheEdge: any) => 
      cacheEdge.from === edge.from && cacheEdge.to === edge.to
    ).length === 0) {
      cacheEdges.push(edge);
    }
  }
  const length3 = cacheEdges.length;
  if (length2 !== length3)
    console.log(`add ${length3 - length2} edge(s) from step data not contained in cache`);
    
  if (!(length1 === length2 && length2 === length3)) {
    console.log("save updated cache data") 
    await controller.db.collection("xd2").doc(params.xdId).update({edges: cacheEdges});
    // console.log(edges);
  } else {
    // console.log("edges cache data same as step data")
  }

  const xdmaster = (await 
    controller.db.collection("cache").doc("xd2").get()).data() || {nodes: {}};

  let xddata = {
    name: params.xd.name,
    cases: params.xd?.cases || [],
    nodes: params.xd.nodes,
    nodeMaster: params.xd.nodeMaster,
    sequence: params.xd.sequence,
    sections: params.xd.sections,
    edges: cacheEdges,
    steps: steps
  };
  
  controller.setState({
    xdId: params.xdId,
    xddata: xddata,
    xdmaster: xdmaster,
    xddataInitialLoad: true,
  });

  PrepareXdJourneyData(controller, {
    xddata, 
    setJourneyDataState: (dataState: string) => controller.setState({xdJourneyDataState: dataState})
  });
}

export const ExportGridToExcel: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.xddata?.sections) return;
  const table = document.createElement("table");
  const workbook = XLSX.utils.table_to_book(table);
  const ws = workbook.Sheets["Sheet1"];
  XLSX.utils.sheet_add_aoa(
    ws, 
    state.xddata.sections.map((section: any) => [section.name]), 
    {origin: "A1"}
  );
  XLSX.writeFile(workbook, `${state.xddata.name}.xlsx`);
}

export const SaveNodeDetailDoc: Handler = async (controller, params) => {
  let xdId = controller.getState().xdId;
  if (!xdId) return console.log("xdId not set");
  const images = params.doc.filter((item: any) => item.type === "image" && item.id)
    .map((item: any) => parseInt(item.id));
  let nextImage = images.length === 0 ? 1 : Math.max(...images) + 1;
  const cv = params.cv;
  let updatedDoc = []
  let newImages = [];
  
  // Convert to doc format with image id and save in firestore
  for (const item of params.doc) {
    if (item.type === "image") {
      if (item?.id) {
        updatedDoc.push({
          type: item.type, id: item.id,
          ...(item?.removed ? {removed: true} : {})
        });  
      } else if (!(item?.removed)) {
        updatedDoc.push({type: item.type, id: nextImage});  
        newImages.push({...item, id: nextImage});
        nextImage += 1;
      }
    } else {
      updatedDoc.push(item);
    }
  }
  
  const stepData = {...(await controller.db.collection("xd2").doc(xdId).collection("xdstep").doc(params.stepId).get()).data()};
  stepData.nodeDetail[params.loc.id].doc = updatedDoc;
  controller.db.collection("xd2").doc(xdId).collection("xdstep")
    .doc(params.stepId)
    .update({nodeDetail: stepData.nodeDetail});

  // Save image in gcs
  for (const item of newImages) {
    const image = new Image();
    image.onload = async (event: any) => {
      // Create thumb
      const orig = cv.imread(event.target);
      const [height, width] = orig.matSize;
      var final = new cv.Mat()
      if (height / thumbSize > width / thumbSize)
        cv.resize(orig, final, new cv.Size(width / height * thumbSize, thumbSize), 0, 0, cv.INTER_AREA);
      else
        cv.resize(orig, final, new cv.Size(thumbSize, height / width * thumbSize), 0, 0, cv.INTER_AREA);
      const thumb = document.createElement("canvas");
      cv.imshow(thumb, final);

      // Save to cloud
      const prefix = `gs://ismor-xd/xdstep2/${xdId}/${params.stepId}/${params.loc.id}/images/`
      await controller.storage
        .refFromURL(`${prefix}${item.id}.png`)
        .putString(image.src, 'data_url');

      await controller.storage
        .refFromURL(`${prefix}${item.id}.thumb.png`)
        .putString(thumb.toDataURL(), 'data_url');
      
      thumb.remove();
    }
    image.src = item.detail;
  }

  // Update state
  let {xddata} = controller.getState();
  if (xddata) {
    xddata.steps[params.stepId] = {id: params.stepId, ...stepData as Step}
  }
  controller.setState({xddata: Object.assign({}, xddata)});
}

export const GetNodeDetailDoc: Handler = async (controller, params) => {
  controller.setState({docInfo: []});
  let { xdId, xddata } = controller.getState();
  if (!xdId || !xddata?.steps?.[params.stepId]) return console.log("no xdId or stepId data");
  
  let prefix = "";
  if (params.forClient) {
    prefix = `gs://ismor-xd-publish/xdstep2/${xdId}/${params.stepId}/${params.loc.id}/images/`;
  } else {
    prefix = `gs://ismor-xd/xdstep2/${xdId}/${params.stepId}/${params.loc.id}/images/`;
  }
  // const stepData = {...(await controller.db.collection("xd2").doc(xdId).collection("xdstep").doc(params.stepId).get()).data()};
  // let docInfo = stepData?.nodeDetail?.[params.loc.id]?.doc || [];
  let docInfo = xddata.steps[params.stepId].nodeDetail?.[params.loc.id]?.doc || [];
  docInfo = await Promise.all(docInfo
    .filter((doc: NodeDetailDocItem) => !(doc?.removed))
    .map(async (doc: NodeDetailDocItem) => 
      doc.type === "text" ? 
        doc
      : doc.type === "image" ?
        {
          ...doc,
          detail: await controller.storage.refFromURL(`${prefix}${doc.id}.png`).getDownloadURL()
        }
      : doc
    ));
  controller.setState({
    docInfo: docInfo
  });
}

export const MoveEdge: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (!(Number.isInteger(params.fromEdgeIndex) && Number.isInteger(params.toEdgeIndex))) return;
  if (!state.xddata) return;
  const edges = Object.assign([], state.xddata?.edges || []);
  let newEdges: any[] = [];
  for (var index=0; index < (state.xddata?.edges?.length || 0); index++) {
    if (index === params.fromEdgeIndex)
      continue;
    if (index === params.toEdgeIndex)
      newEdges.push(edges[params.fromEdgeIndex]);
    newEdges.push(edges[index]);
  }
  if (params.toEdgeIndex === edges.length)
    newEdges.push(edges[params.fromEdgeIndex]);
  state.xddata.edges = newEdges;
  controller.setState({xddata: state.xddata});
  await controller.db.collection("xd2").doc(state.xdId).update({edges: newEdges});
}

export const MoveSection: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (!state.xddata?.sections) return;
  
  const sectionToMove = state.xddata.sections[params.fromSection];
  state.xddata.sections.splice(params.fromSection, 1);
  state.xddata.sections.splice(params.toSection, 0, sectionToMove);
  controller.setState({xddata: state.xddata});
  await controller.db.collection("xd2")
    .doc(state.xdId)
    .update({sections: state.xddata.sections});
}

export const MoveXdStep: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (!state.xddata?.sections) return;

  const stepToMove = state.xddata
    .sections[params.fromStep.sectionIndex]
    .steps[params.fromStep.stepIndex];
  state.xddata.sections[params.fromStep.sectionIndex]
    .steps
    .splice(params.fromStep.stepIndex, 1);
  if (params.fromStep.sectionIndex !== params.toStep.sectionIndex ||
      params.fromStep.stepIndex > params.toStep.stepIndex
  ) {
    state.xddata.sections[params.toStep.sectionIndex]
    .steps
    .splice(params.toStep.stepIndex, 0, stepToMove);
  } else {
    state.xddata.sections[params.toStep.sectionIndex]
    .steps
    .splice(params.toStep.stepIndex - 1, 0, stepToMove);
  }
  controller.setState({xddata: state.xddata});
  await controller.db.collection("xd2")
    .doc(state.xdId)
    .update({sections: state.xddata.sections});
}

export const EditSection: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (!Number.isInteger(params.sectionIndex) || !state.xddata?.sections) return;

  state.xddata.sections[params.sectionIndex].name = params.name;
  state.xddata.sections[params.sectionIndex].nameEn = params.nameEn;
  state.xddata.sections[params.sectionIndex].inactive = params.inactive;
  controller.setState({xddata: state.xddata});

  await controller.db.collection("xd2")
    .doc(state.xdId)
    .update({sections: state.xddata.sections});
}

export const AddSection: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (!state.xddata?.sections) return

  // Add a step for new section
  const blankStep = {
    description: "New Step", 
    nodeDetail: {}, 
    dependOn: [],
    xd: state.xdId,
  }
  const newStep = await controller.db.collection("xd2").doc(state.xdId).collection("xdstep").add(blankStep);

  // Update state
  state.xddata.sections.splice(
    params.sectionIndex + 1, 
    0, 
    {name: "New Section", steps: [newStep.id]}
  );
  state.xddata.steps[newStep.id] = {id: newStep.id, ...blankStep};
  controller.setState({xddata: state.xddata});

  // Update Firestore xd
  await controller.db.collection("xd2")
    .doc(state.xdId)
    .update({sections: state.xddata.sections});
}

export const ChangeXD: Handler = async (controller, params) => {
  if (!params.xdId) return;

  const state = controller.getState();
  controller.setState({
    xdId: params.xdId,
    xddata: {
      name: "",
      cases: [],
      nodes: [],
      nodeMaster: {},
      steps: {},
      sections: [],
      sequence: [],
      edges: [],
    },
    xdmaster: state.xdmaster,
    xddataInitialLoad: false,
  });

  let xd = (await 
    controller.db.collection("xd2")
    .doc(params.xdId)
    .get()
  ).data();
  if (!xd) return;

  await controller.db.collection("preferences")
  .doc(controller.user.email)
  .update({xd: params.xdId});

  LoadXD(controller, {});
}

export const SaveNode: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  // console.log(params);

  if (!state.xddata?.nodeMaster) return;

  if (params.node?.id) {
    let {id, ...data} = params.node;
    state.xddata.nodeMaster[id] = data;
    controller.setState({
      xddata: state.xddata
    });
    await controller.db.collection("xd2").doc(state.xdId).update({
      nodeMaster: state.xddata.nodeMaster
    });
  } else {
    if (!state.xddata?.nodes) return;
    let data = params.node;
    
    // Calculate new id
    let xddata = {...state.xddata};
    if (!xddata) return;
    const maxId = Math.max(
      ...Object.keys(xddata.nodeMaster).map((id: string) => parseInt(id)));
    const newId = (maxId + 1).toString();
    
    xddata.nodeMaster[newId] = data;
    xddata.nodes.push(newId);
  
    // Update state
    controller.setState({
      xddata: xddata, 
    });

    // Update firestore
    await controller.db.collection("xd2").doc(state.xdId).update({
      nodeMaster: state.xddata.nodeMaster,
      nodes: state.xddata.nodes
    });
  }
}

export const AddNode: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId || !state.xddata || !state.xddata.nodes) return;

  if (params?.data?.addMode === "Existing" && params?.data?.items.length > 0) {
    let addedNodeIds = params.data.items
      .filter((item: any) => !state.xddata?.nodes.includes(item.id))
      .map((item: any) => item.id)
    state.xddata.nodes = [...state.xddata.nodes, ...addedNodeIds];
    controller.setState({
      xddata: state.xddata
    });
    await controller.db.collection("xd2").doc(state.xdId).update({
      nodes: state.xddata.nodes,
    });
  }
}

export const SwapNode: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  const temp = state.xddata!.nodes[params.indices[0]];
  state.xddata!.nodes[params.indices[0]] = state.xddata!.nodes[params.indices[1]];
  state.xddata!.nodes[params.indices[1]] = temp;
  controller.setState({xddata: state.xddata});
  await controller.db.collection("xd2").doc(state.xdId).update({
    name: state.xddata?.name || "",
    nodes: state.xddata?.nodes || [],
  });
}

export const MoveNode: Handler = async (controller, params) => {
  if (!(Number.isInteger(params.fromNode) && Number.isInteger(params.toNode))) 
    return;
  
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (! state.xddata?.nodes) return;
  
  const nodes = Object.assign([], state.xddata.nodes);
  let newNodes: string[]  = [];
  for (var index=0; index < nodes.length; index++) {
    if (index === params.fromNode){
      continue;
    }
      
    if (index === params.toNode) {
      newNodes.push(nodes[params.fromNode]);
    }
    newNodes.push(nodes[index])
  }
  if (params.toNode === nodes.length)
    newNodes.push(nodes[params.fromNode]);
  state.xddata.nodes = newNodes;
  controller.setState({xddata: state.xddata});
  await controller.db.collection("xd2").doc(state.xdId).update({
    nodes: state.xddata?.nodes || [],
  });
}

export const SaveXdStep: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  const {id, ...data} = params.step;
  if (state.xddata?.steps[id]) {
    await controller.db.collection("xd2").doc(state.xdId).collection("xdstep")
            .doc(id)
            .set(state.xddata?.steps[id]);
  }
}

export const ModifyStepRole: Handler = async (controller, params) => {
  controller.setProp(
    `xddata.steps.${params.loc.step.id}.nodeDetail`, 
    params.nodeDetail
  );
  SaveXdStep(controller, {step: params.loc.step});
}

export const AddXdStep: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (state?.xddata?.steps && Number.isInteger(params.stepIndex)) {
    let previousStep = (await controller.db.collection("xd2").doc(state.xdId).collection("xdstep").doc(params.step.id).get()).data();
    if (!previousStep) return;

    // Create new step in Firestore xdstep
    const blankStep = {
      description: "New Step", 
      nodeDetail: {}, 
      dependOn: [],
      xd: state.xdId,
    }
    const newStep = await controller.db.collection("xd2").doc(state.xdId).collection("xdstep").add(blankStep);

    // Update state
    state.xddata.sections[params.sectionIndex].steps.splice(params.stepIndex + 1, 0, newStep.id);
    state.xddata.steps[newStep.id] = {id: newStep.id, ...blankStep};
    // state.xddata.steps[params.step.id].dependOn = previousStep.dependOn;
    // state.xddata.edges.push({from: params.step.id, to: newStep.id, optional: false});
    controller.setState({xddata: state.xddata});
    
    // Update Firestore xd
    await controller.db.collection("xd2")
      .doc(state.xdId)
      .update({
        sections: state.xddata.sections,
        // edges: state.xddata.edges,
      });

    // Update previous Step
    // previousStep.dependOn = [...previousStep.dependOn, newStep.id];
    // await controller.db.collection("xd2").doc(state.xdId).collection("xdstep")
    //   .doc(params.step.id)
    //   .update({dependOn: previousStep.dependOn});
  }
}

export const AddXdDependOn: Handler = async (controller, params) => {
  let state = Object.assign({}, controller.getState());
  if (!state.xdId) return console.log("no xdId set");
  if (state?.xddata && params?.to && params?.from && params.to !== params.from) {
    // Prevent graph with no entry point
    const entryCount = Object.values(state.xddata.steps)
      .filter((step: Step) => step.id !== params.from && step.dependOn.length === 0).length;
    if (entryCount > 0 && !state.xddata.steps[params.from].dependOn.includes(params.to)) {
      state.xddata.steps[params.from].dependOn = [
        ...state.xddata.steps[params.from].dependOn,
        params.to
      ];
      state.xddata.edges.push({from: params.from, to: params.to, optional: false})
      controller.setState({xddata: state.xddata});
      const {id, ...data} = state.xddata.steps[params.from];
      await controller.db.collection("xd2").doc(state.xdId).collection("xdstep").doc(id).update({dependOn: data.dependOn});
      await controller.db.collection("xd2").doc(state.xdId).update({edges: state.xddata.edges});
    } else {
      console.log("Cannot add xd dependOn");
      console.log("duplicate", state.xddata.steps[params.from].dependOn.includes(params.to));
      console.log("entrycount", entryCount);
    }
  }
}

export const RemoveXdDependOn: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (state?.xddata && params?.to && params?.from) {
    state.xddata.steps[params.from].dependOn = 
      state.xddata.steps[params.from].dependOn
        .filter((id: string) => id !== params.to);
    state.xddata.steps[params.from].dependOnOptional = 
      (state.xddata.steps[params.from]?.dependOnOptional || [])
        .filter((id: string) => id !== params.to);
    state.xddata.edges = state.xddata.edges.filter(
      (edge: any) => !(edge.from === params.from && edge.to === params.to));
    controller.setState({xddata: state.xddata});
    const {id, ...data} = state.xddata.steps[params.from];
    await controller.db.collection("xd2").doc(state.xdId).collection("xdstep").doc(id).update({
      dependOn: data.dependOn,
      dependOnOptional: data.dependOnOptional
    });
    await controller.db.collection("xd2").doc(state.xdId).update({edges: state.xddata.edges});
  }
}

export const ToggleXdDependOnOptional: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (state?.xddata && params?.to && params?.from) {
    state.xddata.steps[params.from].dependOnOptional 
      = state.xddata.steps[params.from].dependOnOptional || []
    if (state.xddata.steps[params.from].dependOnOptional!.includes(params.to)) {
      state.xddata.steps[params.from].dependOnOptional = 
        state.xddata.steps[params.from].dependOnOptional!.filter(
          (id: string) => id !== params.to)
    } else {
      state.xddata.steps[params.from].dependOnOptional?.push(params.to)
    }
    let found = false
    for (const item of state.xddata.edges) {
      if (item.from === params.from && item.to === params.to) {
        found = true;
        item.optional = !item.optional
      }
    }
    if (!found)
      state.xddata.edges.push({from: params.from, to: params.to, optional: true})
    controller.setState({xddata: state.xddata});
    const {id, ...data} = state.xddata.steps[params.from];
    await controller.db.collection("xd2").doc(state.xdId).collection("xdstep").doc(id)
      .update({dependOnOptional: data.dependOnOptional});
    await controller.db.collection("xd2").doc(state.xdId).update({edges: state.xddata.edges});
  }
}

export const DownloadSpec: Handler = async (controller, params) => {
  await SpecRender(controller, params);
}

export const GetXdCaseList: Handler = async (controller, params) => {  
  controller.setState({xdCases: [], xdProblemDict: {}});
  let state = controller.getState();
  if (!state.xdId) return console.error("No state.xdId");

  let xdData = 
    (await (controller.db.collection("xd2").doc(state.xdId).get()))?.data() || {};
  let caseIdList = xdData?.cases || [];
  let xdCaseDict = Object.fromEntries(
    (await controller.db.collection("xdcase").where("xdId", "==", state.xdId).get())
      .docs.map((doc: any) => ([doc.id, {id: doc.id, ...doc.data()}])));
  let xdCases = caseIdList.map((caseId: string) => xdCaseDict[caseId]);
  let xdProblemDict = Object.fromEntries(
    (await controller.db.collection("xdproblem")
      .where("xdId", "==", state.xdId)
      .where("removed", "==", false)
      .get()
    ).docs?.map((doc: any) => ([doc.id, {id: doc.id, ...doc.data()}])) || []);
  controller.setState({xdCases, xdProblemDict});

  // Add snapshot listener for xdcase
  controller.data.unsubscribeXdCase = 
    controller.db.collection("xdcase")
    .where("xdId", "==", state.xdId)
    .onSnapshot({
      next: async (querySnapshot: any) => {
        let state = controller.getState();
        if (!state.xdId) return console.log("xdId not set");
        let xdDoc = 
          (await controller.db.collection("xd2").doc(state.xdId).get())?.data() || {};
        let xdCaseDict = Object.fromEntries(
          (state.xdCases || []).map((xdCase: any) => ([xdCase.id, xdCase])));
        for (let change of querySnapshot.docChanges()) {
          // console.log("case", change.type, change.doc.id, change.doc.data());
          xdCaseDict[change.doc.id] = {id: change.doc.id, ...change.doc.data()};
        }
        let xdCases = (xdDoc.cases || []).map((caseId: string) => (xdCaseDict[caseId]));
        controller.setState({xdCases});        
      },
      error: (e: any) => console.log(e)
    });
  
  // Add snapshot listener for xdproblem
  controller.data.unsubscribeXdProblem = 
    controller.db.collection("xdproblem")
    .where("xdId", "==", state.xdId)
    .where("removed", "==", false)
    .onSnapshot({ 
      next: (querySnapshot: any) => {
        let state = controller.getState();
        let xdProblemDict = state.xdProblemDict;
        for (let change of querySnapshot.docChanges()) {
          // console.log("problem", change.type, change.doc.id, change.doc.data());
          if (change.doc.data().removed) continue
          xdProblemDict[change.doc.id] = {id: change.doc.id, ...change.doc.data()};
        }
        controller.setState({xdProblemDict});
      },
      error: (e: any) => console.log(e)
    });

  return {xdCases, xdProblemDict}
}

export const AddXdCaseSeq: Handler = async (controller, params) => {
  let { xdCaseId, stepId, description, seqIndex } = params;
  if (!xdCaseId || !stepId) return
  const xdCase = 
    (await controller.db.collection("xdcase").doc(xdCaseId).get());
  if (!xdCase.exists) return
  let sequence = xdCase?.data()?.sequence || [];
  let newSeq = {stepId, description, problems: []};
  if (Number.isInteger(seqIndex) && seqIndex !== null) {
    sequence.splice(seqIndex + 1, 0, newSeq);
  } else {
    sequence.push(newSeq);
  }
  await controller.db.collection("xdcase").doc(xdCaseId).update({sequence});
}

export const DeleteXdCaseSeq: Handler = async (controller, params) => {
  let { xdCaseId, seqToDelete } = params;
  if (!xdCaseId || !Number.isInteger(seqToDelete)) return console.log("Invalid params");
  const xdCase = 
    (await controller.db.collection("xdcase").doc(xdCaseId).get());
  if (!xdCase.exists) return
  let sequence = xdCase?.data()?.sequence || [];
  let itemToDelete = sequence?.[seqToDelete];
  if (!itemToDelete?.problems) 
    return console.log(`Item to delete or its problems not found at index: ${seqToDelete}`);
  let batch = controller.db.batch();
  let seqDeletedAt = moment().format('YYYY-MM-DDTHH:mm:ss')
  for (const problemItem of itemToDelete.problems) {
    if (problemItem?.id) {
      // console.log(problemItem.id, problemItem.description);
      batch.update(
        controller.db.collection("xdproblem").doc(problemItem.id), 
        { seqDeleted: true, seqDeletedAt });
    } 
  };
  batch.update(controller.db.collection("xdcase").doc(xdCaseId), {
    sequence: sequence.filter((item: any, index: number) => index !== seqToDelete)});
  await batch.commit();
}

export const SwapXdCaseSeq: Handler = async (controller, params) => {
  let {xdCaseId, seqToMove, seqToMoveTo} = params;
  if (!xdCaseId || !Number.isInteger(seqToMove) || !Number.isInteger(seqToMoveTo)) 
    return console.log("insufficient params");
  let state = controller.getState();  
  let selectedXdCase = (state.xdCases || []).find((item: any) => item.id === xdCaseId);
  if (!selectedXdCase) return console.log(`xdCase not available for id: ${xdCaseId}`)
  let sequence = selectedXdCase?.sequence || [];
  if (!sequence?.[seqToMove]) return console.log(`Sequence ${seqToMove} not present`);
  let newSequence: any[] = [];
  for (var i = 0; i < sequence.length; i++) {
    if (i === seqToMove) continue
    else if (i === seqToMoveTo) {
      if (seqToMove < seqToMoveTo) {
        newSequence.push(sequence[i]);
        newSequence.push(sequence[seqToMove]);
      } else {
        newSequence.push(sequence[seqToMove]);
        newSequence.push(sequence[i]);
      }
    } else
      newSequence.push(sequence[i]);
  }
  controller.db.collection("xdcase").doc(xdCaseId).update({sequence: newSequence});
}

export const AddOrEditXdProblem: Handler = async (controller, params) => {
  let {
    mode, xdCaseId, seqIndex, nodeId, problemId, 
    testId, testSeqIndex, description, issues
  } = params;
  let state = controller.getState();
  let xdProblemDict = state.xdProblemDict || {};
  let selectedXdCase = (state.xdCases || []).find((item: any) => item.id === xdCaseId);
  if (!selectedXdCase 
      || !Number.isInteger(seqIndex) 
      || (["add", "edit"].includes(mode) && !nodeId)
      || (["edit", "delete"].includes(mode) && !problemId)
  )  return console.error("Insufficient params");
  const xdCaseDoc = 
    (await controller.db.collection("xdcase").doc(xdCaseId).get());
  let xdCaseData = xdCaseDoc.data() || {};
  if (mode === "add") {
    let problemItem = {
      xdId: xdCaseData.xdId,
      caseId: xdCaseId,
      createdBy: controller.user.email,
      createdAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
      removed: false,
      testId: testId || null,
      testSeqIndex: Number.isInteger(testSeqIndex) ? testSeqIndex : null,
      nodeId,
      description, 
      issues,
    };
    let problemDoc = await controller.db.collection("xdproblem").add(problemItem);
    // console.log(problemDoc.id);
    xdProblemDict[problemDoc.id] = {id: problemDoc.id, ...problemItem};
    let sequence = xdCaseData.sequence || [];
    let items = sequence?.[seqIndex]?.problems || [];  
    items.push({
      id: problemDoc.id, 
      nodeId,
    });
    sequence[seqIndex].problems = items;
    await controller.db.collection("xdcase").doc(xdCaseId).update({sequence});
    xdCaseData.id = xdCaseId;
    xdCaseData.sequence = sequence;
    controller.setState({
      xdCases: (state.xdCases || []).map((item: any) => item.id === xdCaseId ? xdCaseData : item),
      xdProblemDict
    });
  } else if (mode === "delete") {
    await controller.db.collection("xdproblem").doc(problemId).update({
      deleted: true,
      deletedAt: moment().format('YYYY-MM-DDTHH:mm:ss')
    });
    let sequence = xdCaseData.sequence || [];
    sequence[seqIndex].problems = sequence[seqIndex].problems.filter(
      (problemItem: any) => problemItem.id !== problemId);
    await controller.db.collection("xdcase").doc(xdCaseId).update({sequence});
  } else if (mode === "edit") {
    await controller.db.collection("xdproblem").doc(problemId).update({
      description, issues
    });
    xdProblemDict[problemId].description = description;
    xdProblemDict[problemId].issues = issues;
    controller.setState({xdProblemDict});
  }
}

export const AddXdCase: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return;
  let xdDoc = await controller.db.collection("xd2").doc(state.xdId).get();
  let caseList = xdDoc.data()?.cases || [];
  let newCase: any = {
    removed: false,
    name: params.name,
    sequence: [],
    xdId: state.xdId
  };
  let newCaseDoc = await controller.db.collection("xdcase").add(newCase);
  caseList.push(newCaseDoc.id);
  await controller.db.collection("xd2").doc(state.xdId).update({
    cases: caseList
  });
  newCase.id = newCaseDoc.id;
  controller.setState({
    xdCases: [...(state.xdCases || []), newCase]
  });
}

export const EditXdCase: Handler = async (controller, params) => {
  let { caseId, name } = params;
  if (!caseId || name === "") return console.log("Invalid params");
  controller.db.collection("xdcase").doc(caseId).update({name: name});
}

export const AddXdTest: Handler = async (controller, params) => {
  let { xdCaseId } = params;
  if (!xdCaseId) return console.log("No xdcase Id");
  let state = controller.getState();
  let xdProblemDict = state.xdProblemDict;
  let xdCase = (state.xdCases || []).find((item: any) => item.id === xdCaseId);
  if (!xdCase) return console.log(`Can't find xdCase for id ${xdCaseId}`);

  xdCase.sequence = xdCase.sequence.map((item: any) => {
    let problems = (item?.problems || [])
                .map((problemItem: any) => (xdProblemDict?.[problemItem.id]))
                .filter((problemItem: any) => problemItem)
                .map((problemItem: any) => ({
                  ...problemItem,
                  issues_untested: problemItem?.issues || [],
                  issues_passed: [],
                  issues_failed: [],
                  tester: null,
                  datetime: null,
                }))
    return { ...item, problems }
  });
  let newTest = {
    xdCase: xdCase,
    createdBy: controller.user.email,
    createdAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
    done: false
  };
  let newTestDoc = await controller.db.collection("xdtest").add(newTest);
  controller.setState({
    xdTestList: [
      ...(state.xdTestList || []), 
      {...newTest, id: newTestDoc.id}
    ]});
}

export const GetXdTestList: Handler = async (controller, params) => {
  let { xdId } = controller.getState();
  if (!xdId) return;
  let result = await GetXdCaseList(controller, {});
  let testList = 
    ((await controller.db.collection("xdtest")
      .where("xdCase.xdId", "==", xdId)
      .where("done", "==", (params?.done || false)).get())?.docs || [])
      .map((doc: any) => ({id: doc.id, ...doc.data()}));
  controller.setState({xdTestList: testList});
  
  // Add snapshot listener for xdtest
  controller.data.unsubscribeXdTest = 
    controller.db.collection("xdtest")
    .where("xdCase.xdId", "==", xdId)
    .onSnapshot({ 
      next: (querySnapshot: any) => {
        let state = controller.getState();
        let xdTestDict = Object.fromEntries(
          (state.xdTestList || []).map((xdTest: any) => ([xdTest.id, xdTest])));
        for (let change of querySnapshot.docChanges()) {
          // console.log("case", change.type, change.doc.id, change.doc.data());
          xdTestDict[change.doc.id] = {id: change.doc.id, ...change.doc.data()};
        }
        controller.setState({xdTestList: Object.values(xdTestDict)});
      },
      error: (e: any) => console.log(e)
    });

  // Cleanup deleted problem without referring open test
  let referredProblems = testList
    .filter((xdTest: any) => !xdTest.done)
    .flatMap((xdTest: any) => (
      (xdTest.xdCase?.sequence || [])
      .flatMap((seq: any) => 
        (seq.problems || []).map((problemItem: any) => problemItem.id))
    ))
    .filter((problemId: any) => problemId);
  
  let problemIdToRemoveList = Object.values(result.xdProblemDict || {})
    .filter((problemItem: any) => 
      (problemItem.seqDeleted || problemItem.deleted) && !referredProblems.includes(problemItem.id))
    .map((problemItem: any) => problemItem.id)
    .filter((problemId: any) => problemId);
  // console.log(problemIdToRemoveList);

  let batch = controller.db.batch();
  let removedAt = moment().format('YYYY-MM-DDTHH:mm:ss')
  for (const problemId of problemIdToRemoveList) {
      batch.update(
        controller.db.collection("xdproblem").doc(problemId), 
        { removed: true, removedAt });
  } 
  await batch.commit();
}

export const CloseXdTest: Handler = async (controller, params) => {
  let { testId, setCloseStatus } = params;
  if (!testId) return console.log("No testId");

  let { python } = controller.data;
  if (!python) return console.log("No python available");

  let xdTestList = controller.getState().xdTestList || [];
  let xdTest = xdTestList.find((candidate: any) => candidate.id === params.testId );
  if (!xdTest || !xdTest?.xdCase?.xdId || !xdTest?.xdCase?.id) 
    return console.log("No xdTest with that testId or no xdId / caseId");
  
  let closedDatetime = moment().format('YYYY-MM-DDTHH:mm:ss');
  let data = (xdTest?.xdCase?.sequence || []).flatMap((seq: any) => (
    (seq?.problems || [])
      .flatMap((problem: any) => (   
        (problem?.issues_passed || []).map((issue: number) => (
          {
            closedAt: closedDatetime,
            xdId: xdTest?.xdCase?.xdId,
            step: seq?.stepId,
            node: problem?.nodeId,
            caseId: xdTest?.xdCase?.id,
            caseName: xdTest?.xdCase?.name,
            testId: xdTest?.id,
            problemId: problem?.id,
            tester: problem?.tester,
            datetime: problem?.datetime,
            issueId: issue,
            status: "passed",
          }))
        .concat(
          (problem?.issues_failed || []).map((issue: number) => (
            {
              closedAt: closedDatetime,
              xdId: xdTest?.xdCase?.xdId,
              step: seq?.stepId,
              node: problem?.nodeId,
              caseId: xdTest?.xdCase?.id,
              caseName: xdTest?.xdCase?.name,
              testId: xdTest?.id,
              problemId: problem?.id,
              tester: problem?.tester,
              datetime: problem?.datetime,
              issueId: issue,
              status: "failed",
            }))
        ))
      )
    ));  
  let testers = Array.from(new Set(data.map((item: any) => item.tester).filter((item: any) => item)));
  
  let jobRemaining = testers.length + 1;
  setCloseStatus({closing: true, message: `started ${jobRemaining} job(s) remaining`});
  await UploadXdTestResult(controller, { 
    data, 
    url: `gs://ismor-redmine/xdtestresult/` 
      + `${xdTest.xdCase.xdId}/${closedDatetime.substring(0, 10)}|${testId}.parquet`});
  jobRemaining--;
  for (const tester of testers) {
    setCloseStatus({closing: true, message: `${jobRemaining} job(s) remaining`});
    await UploadXdTestResult(controller, { 
      data: data.filter((item: any) => item.tester === tester), 
      url: `gs://ismor-redmine/xdtestperf/` 
        + `${tester}/${closedDatetime.substring(0, 10)}|${tester}.parquet`});
    jobRemaining--;
  }
  controller.db.collection("xdtest").doc(testId).update({done: true});  
  setCloseStatus({closing: false, message: `Done`});
}

const UploadXdTestResult: Handler = async (controller, params) => {
  let { python } = controller.data;
  if (!python) return console.log("No python available");

  let {duck, duckCon} = controller.data;
  if (!duck || !duckCon) return console.log("No duck available");
  
  let { data, url } = params;
  if (!data?.length || !url ) return console.log("No data");
  if (data?.length === 0) return console.log("data length = 0. no-op.")
  
  let downloadData  = null;
  try {
    let downloadUrl = await controller.storage.refFromURL(url)
      .getDownloadURL();
    let downloadResult = await fetch(downloadUrl)
    downloadData = await downloadResult.arrayBuffer();
  } catch (e: any) { console.log(e) }

  try {
    duckCon.query(`drop table if exists xdtemp; drop table if exists newdata;`);
    await duck.dropFiles();
    if (downloadData === null) {
      await duckCon.insertArrowTable( 
        tableFromJSON(data),   
        {name: "xdtemp", schema: "main", create: true});
    } else {
      await duckCon.insertArrowTable( 
        tableFromJSON(data),   
        {name: "newdata", schema: "main", create: true});
      await duck.registerFileBuffer('original.parquet', new Uint8Array(downloadData));
      await duckCon.query(`create table xdtemp as from read_parquet('original.parquet');`);
      await duckCon.query(`insert into xdtemp from newdata;`);  
    }
    await duckCon.query(`
      create or replace table xdtempfinal as 
      from xdtemp 
      select * replace(cast(issueId as integer) as issueId)
      group by all`);
    await duckCon.send(`
      copy (
        from xdtempfinal 
        select * 
        order by status, xdId, caseId, testId, problemId
      ) 
      to 'buffer.parquet' (format 'parquet')`);
    const arrayBuffer = await duck.copyFileToBuffer('buffer.parquet');
    await controller.storage.refFromURL(url).put(arrayBuffer);
  } catch (e: any) { console.log(e) }
}

export const UpdateXdTest: Handler = async (controller, params) => {
  let {testId, seqIndex, problemItemId, changeType, issueId} = params;
  let xdTestList = controller.getState().xdTestList || [];
  let testIndex = null
  for (var i = 0; i < xdTestList.length; i++) {
    if (xdTestList[i].id === testId) {
      testIndex = i;
      let seq = xdTestList[i].xdCase.sequence[seqIndex];
      for (var j = 0; j < seq.problems.length; j++) {
        if (seq.problems[j].id === problemItemId) {
          seq.problems[j].tester = controller.user.email;
          seq.problems[j].datetime = moment().format('YYYY-MM-DDTHH:mm:ss');
          if (changeType === "issue_passed") {
            if (!seq.problems[j].issues_passed.includes(issueId))
              seq.problems[j].issues_passed.push(issueId)
            seq.problems[j].issues_failed = 
              seq.problems[j].issues_failed.filter((id: string) => id !== issueId);
            seq.problems[j].issues_untested = 
              seq.problems[j].issues_untested.filter((id: string) => id !== issueId);
          } else if (changeType === "issue_failed") {
            if (!seq.problems[j].issues_failed.includes(issueId))
              seq.problems[j].issues_failed.push(issueId)
            seq.problems[j].issues_passed = 
              seq.problems[j].issues_passed.filter((id: string) => id !== issueId);
            seq.problems[j].issues_untested = 
              seq.problems[j].issues_untested.filter((id: string) => id !== issueId);
          } else if (changeType === "issue_untested") {
            if (!seq.problems[j].issues_untested.includes(issueId))
              seq.problems[j].issues_untested.push(issueId)
            seq.problems[j].issues_passed = 
              seq.problems[j].issues_passed.filter((id: string) => id !== issueId);
            seq.problems[j].issues_failed = 
              seq.problems[j].issues_failed.filter((id: string) => id !== issueId);
          }
          break;
        }
      }
      xdTestList[i]
      break;
    }
  }
  if (testIndex !== null) {
    let {id, ...data} = xdTestList[testIndex];
    controller.db.collection("xdtest").doc(id).set(data);
    controller.setState({xdTestList});
  }
}

export const GetXdTestSummary: Handler = async (controller, params) => {
  let {xdId, all} = params;  
  if (!xdId && !all) return console.log("no xdId and 'all params' not set");
  let {duck, duckCon} = controller.data;
  if (!duckCon || !duck) return console.log("No duck con.")
  let urlList = [];
  if (all) {
    let prefixes = (await controller.storage.refFromURL(`gs://ismor-redmine/xdtestresult`).listAll()).prefixes;
    urlList = (await Promise.all(prefixes.map(async (prefix: any) => (await prefix.listAll()).items || [])))
      .flatMap((item: any) => item).map((item: any) => item.fullPath);
  } else {
    urlList = (
      (await controller.storage.refFromURL(`gs://ismor-redmine/xdtestresult/${xdId}`).listAll()).items || []
    ).map((item: any) => item.fullPath);
  }
  let downloadUrlList = await Promise.all(
    urlList.map(async (fullPath: string) => (
      await controller.storage.refFromURL(`gs://ismor-redmine/${fullPath}`).getDownloadURL()
    )));
  try {
    try {
      await duck.dropFiles();
    } catch (e: any) { console.log(e) }
    await duckCon.query(`drop table if exists xdtestresult;`);
    let arrayBufferList = await Promise.all(
      downloadUrlList.map(async (url: string) => (await fetch(url)).arrayBuffer()));
    for (const index in arrayBufferList) { 
      await duck.registerFileBuffer(`testresult/${index}.parquet`, new Uint8Array(arrayBufferList[index]));
    }    
    await duckCon.query(`create table xdtestresult as from read_parquet('testresult/*.parquet');`);
    params?.setDataReady("ok");  
  } catch (e: any) { 
    console.log(e);
    params?.setDataReady("error"); 
  }    
}

export const PrepareXdJourneyData: Handler = async (controller, params) => {
  let { xddata, setJourneyDataState } = params;
  setJourneyDataState("init");
  let incompleteData = [xddata.nodes, xddata.edges, xddata.sections, Object.keys(xddata.steps)]
    .some((items: any[]) => items.length === 0);
  if (!xddata || incompleteData) return;
    // return console.log("Incomplete xddata");
  let {duckCon} = controller.data;
  if (!duckCon) return console.log("No duck con.")

  // Prepare javascript data
  let nodeSeq = Object.fromEntries(
    xddata.nodes.map((node: string, seq: number) => 
      ([node, seq])));
  let nodemaster = Object.entries(xddata.nodeMaster).map((entry: [string, any]) => (
    { nodeId: entry[0], ...entry[1], index: nodeSeq[entry[0]] }
  ));
  let edges = xddata.edges;
  let section = xddata.sections.map((section: any, seq: number) => (
    { seq, name: section.name, inactive: section?.inactive || false}
  ));
  let step_section = xddata.sections.flatMap((section: any, sectionSeq: number) => 
   (section?.steps || []).map((stepId: any, stepSeq: number) => (
    { stepId, sectionSeq, stepSeq }
  )));
  let step = Object.values(xddata.steps).map((step: any) => (
    { stepId: step.id, xdId: step.xd, description: step.description }
  ));
  let stepdoc = Object.values(xddata.steps || {}).flatMap((step: any) => (
    Object.entries(step.nodeDetail || {}).flatMap((nodeDetailEntry: [string, any]) => (
      (nodeDetailEntry[1].doc || []).map((docItem: any, docSeq: number) => (
        {
          docSeq,
          docId: docItem?.id || null,
          detail: docItem.detail || null,
          type: docItem.type,
          removed: docItem?.removed || false,
          nodeId: nodeDetailEntry[0],
          stepId: step.id,
          xdId: step.xd
        }
      ))
    ))
  ));
  let insertData: { [key: string]: any[] } = {nodemaster, edges, section, step_section, step, stepdoc};

  try {
    for (const table in insertData) {
      await duckCon.query(`drop table if exists ${table}`);
    }
    for (const table in insertData) {
      await duckCon.insertArrowTable(tableFromJSON(insertData[table]), 
      {name: table, schema: "main", create: true});
    }
    setJourneyDataState("success");
  } catch (e: any) { 
    console.log(e);
    setJourneyDataState(`fail: ${e}`);
  }
}

export const XdPublish: Handler = async (controller, params) => {
  let { setPublishDialog } = params;
  let {xdId, xddata, xdmaster} = controller.getState();
  if (!setPublishDialog || !xdId || !xddata || !xdmaster) 
    return console.log("Insufficient params or state.");
  
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  let storageweb = (controller as any).storageweb as app.storage.Storage;  
  if (!dbweb || !storageweb) return console.log("No dbweb or storageweb. Can't publish");
    setPublishDialog({"messages": ["Publishing started"], finished: false});

  let res = await dbweb.collection("xd2").doc(xdId).get();
  if (!res.exists) {
    dbweb.collection("xd2").doc(xdId).set({name: xddata.name, users: []});
  }
  
  if (!storageweb) 
    return setPublishDialog({
      "messages": ["No storageweb present", "Cannot publish"], 
      finished: true
    });
  
  storageweb.refFromURL(`gs://ismor-xd-publish/xd2/${xdId}/${xdId}.json`)
    .putString(JSON.stringify({ xdId, xddata, xdmaster }, null, 1))
    .on(app.storage.TaskEvent.STATE_CHANGED, {
      next: (snapshot: any) => {
        setPublishDialog({
          messages: [ `Uploading ${(snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2)}%` ],
          finished: false,
        });
      },
      error: (error: any) => {
        setPublishDialog({"messages": ["Error", error.toString()], finished: true});
      },
      complete: () => {
        setPublishDialog({"messages": [`Publish success xdId=${xdId}`], finished: true});
      }
    });
}

export const GoogleTranslate: Handler = async (controller, params) => {
  let result = await (await fetch(
    `${controller.data.FASTAPI}/translate?text=${encodeURIComponent(params.text)}`
  )).text()
  console.log(result);
  return result
}

// Deprecated -------------------------------------------------------------------

export const SaveXD: Handler = async (controller, params) => {
  // const state = controller.getState();
  // const xddata = controller.getState()?.xddata as ExchangeDiagramData;
  // await controller.db.collection("xd2").doc(state.xdId).set({
  //   name: xddata.name || "",
  //   nodes: xddata.nodes || [],
  //   sequence: Object.keys(xddata.steps || {})
  // });
  // Object.values(xddata.steps).forEach((item: Step) => {
  //   const {id, ...data} = item;
  //   if (id)
  //     controller.db.collection("xd2").doc(state.xdId).collection("xdstep").doc(id).set(data);
  //   else
  //     controller.db.collection("xd2").doc(state.xdId).collection("xdstep").add({xd: xdId, ...data});
  // });
}

export const SwapStep: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.xdId) return console.log("no xdId set");
  if (state?.xddata?.sequence) {
    const temp = state.xddata.sequence[params.indices[1]];
    state.xddata.sequence[params.indices[1]] = state.xddata.sequence[params.indices[0]];
    state.xddata.sequence[params.indices[0]] = temp;
    controller.setState({xddata: state.xddata});
    controller.db.collection("xd2").doc(state.xdId).update({sequence: state.xddata.sequence});
  }
}


// let result = await python.run(
//   "xdtestresult", {
//     originalData: downloadData,
//     newData: params.data
//   }, `
//   import pandas as pd
//   from io import BytesIO
//   import json
//   import base64
//   from js import pyParams    
  
//   newData = pyParams.newData.to_py()
//   if pyParams.originalData is None:
//     data = newData
//   else:
//     originalData = pyParams.originalData.to_py()
//     data = (
//       pd.read_parquet(BytesIO(originalData)).to_dict(orient="records")
//       + newData)
//   df = pd.DataFrame(data).drop_duplicates()
//   df["issueId"] = df["issueId"].astype(int)
//   df.to_parquet("xdtestresult.parquet")
//   with open("xdtestresult.parquet", "rb") as f:
//       pq = f.read()
//   base64data = base64.b64encode(pq).decode()
//   json.dumps({"data": base64data})
//   `);
// let dataUrl = `data:application/octet-stream;base64,${result.data}`;
// let res = await fetch(dataUrl);
// let arrayBuffer = await res.arrayBuffer();
// await controller.storage.refFromURL(url).put(arrayBuffer);
