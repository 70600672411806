import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { getRoleType } from "./XdDetail";

const XDGrid = (props: any) => {

  const [fromNode, setFromNode] = useState<any>(null);
  const [toNode, setToNode] = useState<any>(null);
  const [fromStep, setFromStep] = useState<any>(null);
  const [toStep, setToStep] = useState<any>(null);
  const [initialScroll, setInitialScroll] = useState(false);

  useEffect(() => { 
    setInitialScroll(true) 
  }, []);
  
  useEffect(() => { 
    if (initialScroll 
        && props.sectionRefs?.[props?.selectedSectionIndex]?.current
    ){
      props.sectionRefs[props.selectedSectionIndex]
        .current
        .scrollIntoView(true);
      setInitialScroll(false);
    }
  }, [initialScroll]);

  const swapNode = (indices: [number, number]) => {
    if (!props.xdedit) return;
    props.onEvent({message: "SwapNode", params: {indices: indices}});
  }

  return genGrid(
    "app",
    props,
    swapNode,
    fromNode,
    toNode,
    setFromNode,
    setToNode,
    toStep,
    setToStep,
    fromStep,
    setFromStep,
  )
}

export const genGrid = (
  mode: string,
  props: any,
  swapNode: any,
  fromNode: any,
  toNode: any,
  setFromNode: any,
  setToNode: any,
  toStep: any,
  setToStep: any,
  fromStep: any,
  setFromStep: any,
  ) => {
  return(
    <div>
      {/* Header row */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "200px " + "minmax(0, 1fr) ".repeat(props.cols - 1),
          gridTemplateRows: "100px",
          border: "solid black 1px",
          backgroundColor: "grey",
          gridGap: "1px",
          overflowY: "scroll"
        }}
        children={[
          <XdNode 
            key={0} 
            xdedit={props.xdedit}
            translate={props.translate}
            detail="" 
            type="nodehead"
            loc={{
              step: null,
              id: null,
              node: null
            }}
            showDetailModal={props.showNodeDetail}
          />,
          props.xddata.nodes.map((item: any, node_index: number) => (
            <XdNode 
              key={node_index + 1}
              node_index={node_index} 
              last_index={props.xddata.nodes.length - 1}
              detail={item.name}
              external={item?.external}
              system={item?.system}
              patient={item?.patient}
              color={item?.color}
              type="node"
              loc={{
                step: null,
                id: item.id.toString(),
                node: item
              }}
              showDetailModal={props.showNodeDetail}
              swapNode={swapNode}
              showTool={props.showTool}
              fromNode={fromNode}
              toNode={toNode}
              setFromNode={setFromNode}
              setToNode={setToNode}
              xdedit={props.xdedit}
              translate={props.translate}
              onEvent={props.onEvent}
            />
          )),
        ]}
      />
      {/* Content rows */}
      <div
        style={{
          display: "grid",
          height: mode === "svg" ? "auto" : mode === "html" ? "88vh" :"80vh",
          overflowY: "scroll",
          gridTemplateColumns: "200px " + "minmax(0, 1fr) ".repeat(props.cols - 1),
          gridTemplateRows: props.view.sectionViews.map((section: any) => 
                              ("20px " + "75px ".repeat(section.visibleSteps))).join(""),
          border: "solid black 1px",
          backgroundColor: "grey",
          gridGap: "1px",
        }}
        children={[
          props.sections.flatMap((section: any, sectionIndex: number) => (
            [
              <div 
                key={`section${sectionIndex}`} 
                ref={props.sectionRefs[sectionIndex]}
                onDrop={(e: any) => {
                  e.preventDefault();
                }}
                onDragOver={(e: any) => {
                  e.preventDefault();
                  if (
                    sectionIndex === 0 || 
                    props.sections[sectionIndex - 1].steps.length > 0 ||
                    props.foldedSections.includes(sectionIndex - 1) ||
                    (toStep?.sectionIndex === sectionIndex - 1  
                     && toStep?.stepIndex === 0)
                  ) return;
                  
                  setToStep({
                    sectionIndex: sectionIndex - 1,
                    stepIndex: 0
                  });
                }}
                style={{
                  gridColumn: `1/ span ${props.cols}`, 
                  backgroundColor: section?.inactive ? "#cccccc" : "#e75480", 
                  color: "white", 
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: section.steps.length === 0 
                                  && toStep?.sectionIndex === sectionIndex
                                  && toStep?.stepIndex === 0 
                                  ? "solid blue 2px" : "none"
                }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e: any) => {
                    props.setSelectedSectionIndex(sectionIndex);
                    props.setSectionDetail(true);
                  }}>
                  {`${sectionIndex + 1}. ${section.name}`}
                </div>
                <div>
                  {sectionIndex > 0 &&
                  <Icon 
                    name="angle up"
                    onClick={(e: any) => {
                      if (!props.xdedit) return;
                      props.onEvent({
                        message: "MoveSection", 
                        params: {fromSection: sectionIndex, toSection: sectionIndex - 1}
                      });
                    }}
                  />}
                  {sectionIndex + 1 < props.sections.length && 
                  <Icon 
                    name="angle down"
                    onClick={(e: any) => {
                      if (!props.xdedit) return;
                      props.onEvent({
                        message: "MoveSection", 
                        params: {fromSection: sectionIndex, toSection: sectionIndex + 1}
                      });
                    }}
                  />}
                  <Icon 
                    name="delete"
                    color={props.foldedSections.includes(sectionIndex) ? "teal" : undefined}
                    onClick={(e: any) => {
                      if (!props.foldedSections.includes(sectionIndex)) {
                        props.setFoldedSections([...props.foldedSections, sectionIndex]);
                      } else {
                        props.setFoldedSections(props.foldedSections.filter(
                          (index: number) => index !== sectionIndex));
                      }
                    }}
                  />
                  <Icon 
                    name="plus"
                    onClick={(e: any) => {
                      if (!props.xdedit) return;
                      props.onEvent({message: "AddSection", params: {sectionIndex}})
                    }}
                  />
                </div>
              </div>
            ].concat(props.foldedSections.includes(sectionIndex) ? [] 
              : section.steps.flatMap((step: any, step_index: number) => (
              [
                <Cell 
                  key={`section${sectionIndex}:${(step_index + 1) * props.cols}`} 
                  step_index={step_index}
                  doc={null}
                  // last_index={steps.length - 1}
                  detail={`${step.seq}. ${step.description} (${step.nextSteps})`} 
                  type="step"
                  loc={{ step: step }}
                  addStep={props.addStep}
                  stepIndex={step_index}
                  sectionIndex={sectionIndex}
                  showDetailModal={props.showRowDetail}
                  swapStep={props.swapStep}
                  showTool={props.showTool}
                  fromStep={fromStep}
                  toStep={toStep}
                  setFromStep={setFromStep}
                  setToStep={setToStep}
                  lastSectionStep={section.steps.length - 1}
                  xdedit={props.xdedit}
                  translate={props.translate}
                  onEvent={props.onEvent}
                />
              ].concat(props.xddata.nodes.map((node: any, node_index: number) => (
                Object.keys(step.nodeDetail).includes(node.id.toString()) ?
                <Cell 
                  key={`section${sectionIndex}:${(step_index + 1) * props.cols + node_index + 1}`}
                  detail={step.nodeDetail[node.id].detail}
                  doc={step.nodeDetail[node.id].doc}
                  type={step.nodeDetail[node.id].type}
                  loc={{
                    step: step, 
                    id: node.id.toString(),
                    node: node
                  }}
                  setProp={props.setProp}
                  showDetailModal={props.showCellDetail}
                  fromNode={fromNode}
                  toNode={toNode}
                  node_index={node_index}
                  node_count={props.xddata.nodes.length}
                  stepIndex={step_index}
                  sectionIndex={sectionIndex}
                  fromStep={fromStep}
                  toStep={toStep}
                  lastSectionStep={section.steps.length - 1}
                />
                :
                <Cell 
                  key={`section${sectionIndex}:${(step_index + 1) * props.cols + node_index + 1}`}
                  doc={null}
                  loc={{
                    step: step, 
                    id: node.id.toString(),
                    node: node
                  }}
                  setProp={props.setProp}
                  showDetailModal={props.showCellDetail}
                  fromNode={fromNode}
                  toNode={toNode}
                  node_index={node_index}
                  node_count={props.xddata.nodes.length}
                  stepIndex={step_index}
                  sectionIndex={sectionIndex}
                  fromStep={fromStep}
                  toStep={toStep}
                  lastSectionStep={section.steps.length - 1}
                />
              )))
            )))
          )) 
        ]}
      />
    </div>
  )
}

const XdNode = (props: any) => {
  const node_index = props.node_index;
  const last_index = props.last_index;
  return(
    <div 
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2px",
        backgroundColor: props?.color ? props.color
          : props?.system === "External" ? "yellow"
          : props?.patient ? "#f4cccc" 
          : props?.type === "nodehead" ? "white" 
          : props?.type === "node" ? "cyan" : "white", 
        textAlign: props?.type === "node" ? "center" : "left", 
        cursor: "pointer"
      }}
      onClick={(e: any) => 
        props?.loc && props.showDetailModal(props.loc)}
      >
      <div 
        style={{flex: 1}}
        draggable={props?.xdedit || false}
        onDragStart={(e: any) => {
          if (Number.isInteger(node_index)) 
            props.setFromNode(node_index);
        }}
        onDragOver={(e: any) => {
          e.preventDefault();
          if (Number.isInteger(node_index))
            props.setToNode(node_index);
        }}
        onDragLeave={(e: any) => {
          if (Number.isInteger(node_index) 
            && node_index  === last_index
          ) {
            props.setToNode(last_index + 1);
          }
        }}
        onDrop={(e: any) => {
          e.preventDefault();
        }}
        onDragEnd={(e: any) => {
          if (!props.xdedit) return;
          if (Number.isInteger(props.node_index) && (props.fromNode !== props.toNode)) {
            props.onEvent({
              message: "MoveNode",
              params: {fromNode: props.fromNode, toNode: props.toNode}
            })  
          }
          props.setFromNode(null);
          props.setToNode(null);
        }}>
        <div>{props?.detail || ""}</div>
        {props?.system && <div>{`(${props?.system || ""})`}</div>}
      </div>
      {/* {props?.showTool &&
        <div style={{display: "flex", justifyContent: "space-between", marginBottom: "5px"}}>
          {node_index === 0 || props.type === "nodehead" ? <div></div>:
          <Icon 
            name="angle left"
            onClick={(e: any) => {
              e.stopPropagation();
              props.swapNode([node_index - 1, node_index]);
            }} 
          />}
          <div></div>
          {node_index === last_index || props.type === "nodehead" ? <div></div>:
          <Icon 
            name="angle right"
            onClick={(e: any) => {
              e.stopPropagation();
              props.swapNode([node_index, node_index + 1]);
            }}
          />}
        </div>
      } */}
    </div>
  )
}

const Cell = (props: any) => {
  // const step_index = props.step_index;
  // const last_index = props.last_index;

  return(
    <div 
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "2px",
        backgroundColor:  (  props.node_index === props.fromNode || 
                            (props.sectionIndex === props.fromStep?.sectionIndex && 
                            props.stepIndex === props.fromStep?.stepIndex)
                          ) ? "#cccccc" : getRoleType(props?.type),
        textAlign: props?.type === "step" ? "left" : "center", 
        borderLeft: Number.isInteger(props.node_index) && props.node_index === props.toNode 
                      ? "solid blue 2px" : "none",
        borderRight: Number.isInteger(props.node_index) 
                      && props.node_index + 1 === props.node_count
                      && props.toNode === props.node_count
                      ? "solid blue 2px" : "none",
        borderTop: Number.isInteger(props.sectionIndex) && Number.isInteger(props.stepIndex) 
                    && props.sectionIndex === props.toStep?.sectionIndex
                    && props.stepIndex === props.toStep?.stepIndex
                      ? "solid blue 2px" : "none",
        borderBottom: Number.isInteger(props.sectionIndex) && Number.isInteger(props.stepIndex) 
                    && props.sectionIndex === props.toStep?.sectionIndex
                    && props.stepIndex === props.lastSectionStep
                    && props.toStep.stepIndex === props.lastSectionStep + 1
                      ? "solid blue 2px" : "none",
        cursor: "pointer",
        overflow: "hidden"
      }}
      onClick={(e: any) => {
        props?.loc && props.showDetailModal(props.loc)
      }}
      draggable={(props?.xdedit || false) && (props.setFromStep ? true : false)}
      onDragStart={(e: any) => {
        if (Number.isInteger(props.sectionIndex) && Number.isInteger(props.stepIndex))
          props.setFromStep({
            sectionIndex: props.sectionIndex, 
            stepIndex: props.stepIndex
          });
      }}
      onDragOver={(e: any) => {
        e.preventDefault();
        if (Number.isInteger(props.sectionIndex) && Number.isInteger(props.stepIndex)) {
          if (!(props.sectionIndex === props.toStep?.sectionIndex 
              && props.stepIndex === props.toStep?.stepIndex)
          ) {
            props.setToStep({
              sectionIndex: props.sectionIndex, 
              stepIndex: props.stepIndex
            });
          }
        }
      }}
      onDragLeave={(e: any) => {
        if (Number.isInteger(props.sectionIndex) && Number.isInteger(props.stepIndex)
          && props.stepIndex === props.lastSectionStep
        ) {
          if (!(props.sectionIndex === props.toStep?.sectionIndex 
            && props.lastSectioStep + 1 === props.toStep?.stepIndex)
          ) {
            props.setToStep({
              sectionIndex: props.sectionIndex, 
              stepIndex: props.lastSectionStep + 1
            });
          }
        }
      }}
      onDrop={(e: any) => {
        e.preventDefault();
      }}
      onDragEnd={(e: any) => {
        if (!props.xdedit) return;
        e.preventDefault();
        if (Number.isInteger(props.sectionIndex) && Number.isInteger(props.stepIndex) && 
            !(props.fromStep.sectionIndex === props.toStep?.sectionIndex 
              && props.fromStep.stepIndex === props.toStep?.stepIndex)
        ) {
          props.onEvent({
            message: "MoveXdStep",
            params: {fromStep: props.fromStep, toStep: props.toStep}
          })  
        }
        props.setFromStep(null);
        props.setToStep(null);
      }}>
      <div style={{
          color: props.type === "step" ? "black" : (props?.doc && (props?.doc?.length > 0)) ? "blue" : "red",
          fontSize: 12,
          // fontWeight: props.doc && props.doc.length > 0 ? "bold" : 0
        }}>
        {props?.detail || ""}
      </div>
      {props.type === "step" && props?.showTool ? 
      <div style={{display: "flex", flexDirection: "column"}}>
        {/* {step_index === 0 ? <></> :
        <Icon
          name="angle up"
          color="orange"
          onClick={(e: any) => {
            e.stopPropagation();
            props.swapStep([step_index - 1, step_index])
          }}
        />}
        {step_index === last_index ? <></> :<Icon
          name="angle down"
          color="orange"
          onClick={(e: any) => {
            e.stopPropagation();
            props.swapStep([step_index, step_index + 1])
          }}
        />} */}
        <Icon 
          name="plus"
          color="green" 
          onClick={(e: any) => {
            e.stopPropagation();
            if (!props.xdedit) return;
            props.addStep(props.loc.step, props.stepIndex, props.sectionIndex)
          }}
        />
      </div> 
      : 
      <></>}
    </div>
  )
}

export default XDGrid;
