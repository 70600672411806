import { useEffect, useRef, useState } from "react";
import XLSX from 'xlsx';
import { monthListOfYear, previousYm, thisMonth } from "./Utils";
import { Icon, Modal } from "semantic-ui-react";
import LetterForm from "./LetterForm";
import { yearSummaryByStaff, yearSummaryByRole } from "./YearSummary";

type EditedDetail = {
  staffId: string,
  ym: string,
  value: string,
}

export default function ByYear(props: any) {
  const [editedDetail, setEditedDetail] = useState<EditedDetail | null>(null);
  const [yearSum, setYearSum] = useState<any[]>([]);
  const [year, setYear] = useState<string>(thisMonth.substring(0, 4));
  const [category, setCategory] = useState("base");
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedYm, setSelectedYm] = useState<string | null>(null);
  const [letter, setLetter] = useState<{[key: string]: string}>({pre: "", post: ""});
  const [openLetter, setOpenLetter] = useState(false);
  const [viewMode, setViewMode] = useState("staff");
  const [ymToPopulate, setYmToPopulate] = useState<string|null>(null);

  const yearYm = monthListOfYear(year);

  useEffect(() => {
    if (Object.keys(props.staffDict).length === 0) return;
    let yearSum_: any[] = [];
    if (viewMode === "staff") {
      yearSum_ = yearSummaryByStaff(year, yearYm, props.staffProfile, props.monthDetail);
    } else {
      yearSum_ = yearSummaryByRole(year, yearYm, props.staffProfile, props.monthDetail);
    } 
    setYearSum(yearSum_);
  }, [year, props.staffDict, props.triggerStaff, viewMode]);

  useEffect(() => {
    if (editedDetail !== null)
      inputRef?.current?.focus();
  }, [editedDetail]);

  useEffect(() => {
    if (selectedYm !== null) {
      setLetter(props.letterDict[selectedYm]);
    } else {
      setLetter({pre: "", post: ""});
    }
  }, [selectedYm]);

  const updateStaffDetail = (staffItem: any, column: string) => {
    if (!editedDetail) return console.log('editedDetail is null or undefined');
    if (category !== 'role') {
      props.onEvent({message: "UpdateStaffDetail",
        params: {
          staffId: editedDetail.staffId, ym: editedDetail.ym,
          detailItem: {
            ...staffItem.detailDict?.[column],
            components: {
              ...staffItem.detailDict?.[column]?.components,
              ...({[category]: Number(editedDetail.value)})
            }
          }
        }
      });
    } else if (category === 'role') {
      props.onEvent({message: "UpdateStaffDetail",
        params: {
          staffId: editedDetail.staffId, ym: editedDetail.ym,
          detailItem: {
            ...staffItem.detailDict?.[column],
            role: editedDetail.value || 'no role'
          }
        }
      });
    };
    setEditedDetail(null);
  }

  return(
    <div style={{width: "100%"}}>
      <div
        style={{display: "flex", justifyContent: "space-between", cursor: "pointer"}}>
        <div style={{flex: 1}}>
          {props.staffplus &&
          <div style={{display: "flex"}}>
            <select
              value={category}
              onChange={(e: any) => setCategory(e.target.value)}>
              <option value="base">Base</option>
              <option value="stock">Stock</option>
              <option value="dividend">Dividend</option>
              <option value="bonus">Bonus</option>
              <option value="role">Role</option>
            </select>
          </div>}
        </div>
        <div style={{flex: 1}}>
          <span 
            onClick={(e: any) => setYear((Number(year) - 1).toString())}>
            &lt;&lt;&nbsp;
          </span>
          <span>{year}</span>
          <span
            onClick={(e: any) => setYear((Number(year) + 1).toString())}>
            &nbsp;&gt;&gt;
          </span>
        </div>
        <div style={{display: "flex"}}>
          <div 
            style={{marginRight: "10px", backgroundColor: viewMode === "staff" ? "#eeeeee" : "white"}}
            onClick={(e: any) => {
              setViewMode("staff");
            }}>
            Staff
          </div>
          <div
            style={{backgroundColor: viewMode === "role" ? "#eeeeee" : "white"}}
            onClick={(e: any) => {
              setViewMode("role");
            }}>
            Role
          </div>
        </div>
      </div>
      <div 
        style={{
          display: "grid", 
          gridTemplateColumns: "15% " + `${85 / 12}% `.repeat(12)
        }}>
        {/* Top row with ym ---------------------------------------------------------------------------------------- */}
        {[""].concat(yearYm)
        .map((ym: string, ymIndex: number) => (
          <div 
            key={ymIndex}
            style={{
              border: "solid #cccccc .5px", padding: "2px",
              fontWeight: "bold", 
              backgroundColor: props.letterDict?.[ym] ? "orange": ym === ymToPopulate ? "pink" : "#eeeeee",
            }}>
            <div style={{textAlign: "right"}}>
              <span 
                style={{cursor: "pointer"}}>
                {ym}
              </span>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              {ym !== "" && 
              <>
                {(previousYm(ym) in props.monthDetail) && category === 'base' &&
                <Icon name="arrow right" color="orange" 
                  onClick={(e: any) => props.onEvent({message: "AddMonthDetail", params: {ym}})}
                />}
                <div style={{flex: 1}}></div>
                {(previousYm(ym) in props.monthDetail) && category === 'base' &&
                <Icon name="angle double right" color="red" 
                  onClick={(e: any) => props.onEvent({message: "PopulateYearDetail", 
                    params: {year: year, fromYm: ym, setYmToPopulate}})}
                />}
                <div style={{flex: 1}}></div>
                <Icon 
                  name="folder" color="blue"
                />
                {category === "dividend" && 
                <>
                  <Icon 
                    name="download" color="blue"
                    onClick={(e: any) => {
                      let workbook = XLSX.utils.book_new();
                      workbook.SheetNames.push("Data");
                      let sheet_data = yearSum
                        .filter((staffItem: any) => (
                          staffItem.detailDict[ym]?.components?.dividend > 0
                        ))
                        .sort((a: any, b: any) => (
                          props.staffProfile[a.id]?.employeeId 
                            < props.staffProfile[b.id]?.employeeId ? -1 : 1
                        ))
                        .map((staffItem: any) => ([
                          staffItem.id === "Sum" ? "Employee ID"
                            : props.staffProfile[staffItem.id]?.employeeId,
                          staffItem.id, 
                          staffItem.detailDict[ym]?.components?.dividend
                        ]));
                      const sheet = XLSX.utils.aoa_to_sheet(sheet_data);
                      workbook.Sheets["Data"] = sheet;
                      XLSX.writeFile(workbook, `${ym}.xlsx`, { bookType: "xlsx", type: "buffer" });
                    }}
                  />
                  <Icon 
                    name="envelope" color="blue"
                    onClick={(e: any) => {
                      setSelectedYm(ym);
                      setOpenLetter(true);
                    }}
                  />
                </>}
              </>
              }
            </div>
          </div>
        ))}
        {/* Data table ----------------------------------------------------------------------------------------*/}
        {yearSum
        .sort((a: any, b: any) => a.id < b.id ? -1 : 1)
        .flatMap((staffItem: any, staffIndex: number) => (
          ["name"].concat(yearYm)
          .map((column: string, columnIndex: number) => { return (
            column === "name" ?
            <div 
              style={{
                display: "flex",
                border: "solid #cccccc .5px", textAlign: "left",
                fontWeight: staffItem.id === "Sum" ? "bold": "normal",
                color: props.staffProfile?.[staffItem.id]?.status === "Inactive" 
                      ? "grey" : "black"
              }}
              key={`${staffIndex}:${columnIndex}`}>
              <div>
                {`${staffItem.id}`}
              </div>
              <div style={{flex: 1}}></div>
              <div>
                {`${yearSum.find((item: any) => item.id === staffItem.id)?.start}`}
              </div>
            </div>
            :
            <div
              style={{
                border: "solid #cccccc .5px", textAlign: "right",
                fontWeight: staffItem.id === "Sum" ? "bold": "normal",
                color: props.staffProfile?.[staffItem.id]?.status === "Inactive" 
                      ? "grey" : "black"
              }}
              onClick={(e: any) => {
                if (viewMode === "role") return console.log("Role view is read only");
                if (staffItem.id === "Sum") return;
                if (category !== 'role') {
                  setEditedDetail({
                    staffId: staffItem.id,
                    ym: column,
                    value: staffItem.detailDict?.[column]?.components?.[category]
                  });
                } else {
                  setEditedDetail({
                    staffId: staffItem.id,
                    ym: column,
                    value: staffItem.detailDict?.[column]?.role
                  });
                }
              }}
              key={`${staffIndex}:${columnIndex}`}>
              {editedDetail?.staffId === staffItem.id && editedDetail?.ym === column ?
              <input 
                ref={inputRef}
                value={editedDetail?.value || ''}
                onBlur={(e: any) => {
                  updateStaffDetail(staffItem, column);
                }}
                onKeyUp={(e: any) => {
                  if (e.key !== "Enter") return;
                  updateStaffDetail(staffItem, column);
                }}
                onChange={(e: any) => {
                  if (category !== 'role') {
                    if (Number.isNaN(Number(e.target.value + "0"))) return;
                    setEditedDetail({
                      ...editedDetail,
                      value: e.target.value === "0" ? "0" 
                              : e.target.value.replace(/^0+/, '')
                    });
                  } else if (category === 'role') {
                    setEditedDetail({
                      ...editedDetail,
                      value: e.target.value
                    });
                  }
                }}
                style={{width: "100%"}}
              />
              :
              category !== 'role' ? 
              staffItem.detailDict?.[column]?.components?.[category]
                ?.toLocaleString('en-US', {maximumFractionDigits: 0})
              : category === 'role' ?
              staffItem.detailDict?.[column]?.role
              : 'N/A'}
            </div>
          )})
        ))}
      </div>      
      <Modal
        open={openLetter}
        closeOnDimmerClick={true}
        onClose={(e: any) => {
          setSelectedYm(null);
          setOpenLetter(false);
        }}>
        <LetterForm 
          onEvent={props.onEvent}
          setSelectedYm={setSelectedYm}
          selectedYm={selectedYm}
          setLetter={setLetter}
          letter={letter}
          yearSum={yearSum}
          letterDict={props.letterDict}
          setOpenLetter={setOpenLetter}
        />
      </Modal>
    </div>
  )
}
